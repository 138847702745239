import { DateFilter, DateFilterName, SearchFilterState } from "../../../../hooks/slices/tenderSearchSlice";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { ArrowUpIcon, PinIcon } from "../../../../components/icons";
import { motion } from "framer-motion";
import { DateFilterUtil } from "../../../../utils/dateFilters";
import {
  DefaultSearchSettings,
  updateDefaultSearchSettings,
  useUpdateDefaultSearchSettingsMutation,
} from "../../../../hooks/slices/userSlice";
import { RaiseUser } from "../../../../hooks/raiseUser";
import {showCustomSuccessPopup, showErrorPopup} from "../../../../hooks/slices/snaccSlice";

interface DateFilterDropdownProps {
  label?: string;
  dateFilters: DateFilter[];
  defaultSelectedFilter: DateFilterName;
  select: (dateFilter: DateFilter) => void;
  showPin?: boolean;
}

export const DateFilterDropdown: React.FC<DateFilterDropdownProps> = (props) => {
  let { t } = useTranslation();
  let dispatch = useAppDispatch();
  const searchFilters: SearchFilterState = useAppSelector((state) => state.tenderSearch.searchFilters);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const defaultSearchSettings : DefaultSearchSettings | undefined = RaiseUser().user?.defaultSearchSettings;
  const [currentDefaultSearchSettings, setCurrentDefaultSearchSettings] = useState<DefaultSearchSettings | undefined>(defaultSearchSettings);
  const [updateDefaultSettings, {data, isLoading, isSuccess, isError, error}] = useUpdateDefaultSearchSettingsMutation();
  const getCurrentSelectedElement = (): DateFilter => {
    let selectedDateFilterNames = searchFilters.selectedDateFilters?.map((f) => f.name) ?? [];
    let currentSelectedElement = props.dateFilters.find((f) => selectedDateFilterNames.includes(f.name));
    if (currentSelectedElement !== undefined) return currentSelectedElement;
    else return props.dateFilters.find((f) => f.name === props.defaultSelectedFilter) as DateFilter;
  };
  const isPinned = (dateFilter: DateFilter) => {
      return currentDefaultSearchSettings?.publicationDate === dateFilter.name;
  }
  const getDropdownValues = (): DateFilter[] => {
    let selectedDateFilter = getCurrentSelectedElement();
    let otherFilters = props.dateFilters.filter((f) => f !== selectedDateFilter);
    return DateFilterUtil.sortDateFilters(otherFilters, selectedDateFilter);
  };
  const changeSelectedElement = (newFilter: DateFilter) => {
    props.select(newFilter);
    setDropdownOpen(false);
  };
  const pinOrUnpinDate = () => {
    let currentFilter: DateFilter = getCurrentSelectedElement();
    let pinnedDate = currentDefaultSearchSettings?.publicationDate;
    let currentDateIsPinned = currentFilter.name === pinnedDate;
    let newName = currentDateIsPinned ? undefined : currentFilter.name;
    updateDefaultSettings({publicationDate: newName});
    dispatch(updateDefaultSearchSettings({publicationDate: newName}));
    setCurrentDefaultSearchSettings({publicationDate: newName});
  }
  let currentDateIsPinned = isPinned(getCurrentSelectedElement());
  useEffect(() => {
    if (isSuccess) {dispatch(showCustomSuccessPopup(t("publications.pinSuccess")));}
    if (isError && error) {dispatch(showErrorPopup(error));}
  }, [isSuccess, isError, error, data]);
  if (props.dateFilters.length === 0) return <></>;
  return (
    <>
      <div className={"relative-div"} data-cy={"dropdown"}>
        <div className={"dropdown-title"}>
          <div className={'dropdown-label'}>
            {props.label && <p className={""}>{props.label}</p>}
          </div>
          <div className={"dropdown-container"}>
            <button className={"dropdown-button"}
              type={"button"}
              onClick={() => setDropdownOpen(!dropdownOpen)}
              onBlur={() => setDropdownOpen(false)}
              data-cy={"dropdown-selected-element"}
              style={{width: props.showPin ? "80%" : "100%"}}
            >
                <span>{t("dateFilters." + getCurrentSelectedElement().name)}</span>
                  <motion.span
                    animate={{ rotate: dropdownOpen ? 180 : 0 }}
                    className={dropdownOpen ? "dropdown-arrow rotated" : "dropdown-arrow"}
                  >
                    <ArrowUpIcon />
                  </motion.span>
            </button>
            {props.showPin && (
                <motion.span
                    title={currentDateIsPinned ? t("publications.unpinDate") : t("publications.pinDate")}
                    onClick={pinOrUnpinDate} className={currentDateIsPinned ? 'pin-button active' : 'pin-button'}
                    animate={{
                      y: currentDateIsPinned ? 2 : 0,
                      transition: { type: 'spring', duration:.2, bounce: 0.2}}}>
                  <PinIcon/>
                </motion.span>
            )}
          </div>
        </div>
        <motion.div
          animate={{ height: dropdownOpen ? "auto" : 0 }}
          className={dropdownOpen ? "dropdown-alignment dropdown-show" : "dropdown-alignment dropdown-hidden"}
          style={{width: props.showPin ? "80%" : "100%"}}
        >
          <div className={"dropdown-design"}>
            {getDropdownValues().map((dropdownElement, i) => (
              <span
                key={i}
                onMouseDown={() => changeSelectedElement(dropdownElement)} //not onclick because that interferes with the blur event
                data-cy={"dropdown-unselected-element"}
              >
                {t("dateFilters." + dropdownElement.name)}
              </span>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
};



