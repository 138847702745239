import {ResourceKey} from "i18next";

export const DateFilters: ResourceKey ={

	PUBLICATION_DATE_EVERYTHING: "Tout",
	PUBLICATION_DATE_YESTERDAY: "Le plus récent",
	PUBLICATION_DATE_LAST_WEEK: "Semaine dernière",
	PUBLICATION_DATE_LAST_MONTH: "Mois dernier",
	PUBLICATION_DATE_LAST_TWO_MONTHS: "Deux derniers mois",
	PUBLICATION_DATE_LAST_YEAR: "Année dernière",
	PUBLICATION_DATE_CUSTOM: "Custom",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING: "Tout",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY: "Le plus récent",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK: "Semaine dernière",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH: "Mois dernier",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR: "Année dernière",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM: "Custom",
	ESTIMATED_RENEWAL_DATE_NEXT_MONTH: "Mois prochain",
	ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS: "Trois mois",
	ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS: "Six mois",
	ESTIMATED_RENEWAL_DATE_NEXT_YEAR: "Année prochaine",
	ESTIMATED_RENEWAL_DATE_ALL: "Tout",
	ESTIMATED_RENEWAL_DATE_CUSTOM: "Custom",
	DEADLINE_EVERYTHING: "Tout",
	DEADLINE_NOT_YET_EXPIRED: "Pas encore expiré",
	DEADLINE_IN_7_DAYS: "7 jours",
	DEADLINE_IN_14_DAYS: "14 jours",
	DEADLINE_IN_1_MONTH: "1 mois",
	DEADLINE_IN_2_MONTHS: "2 mois",
	DEADLINE_CUSTOM: "Custom",

};
