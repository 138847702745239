import React from "react";
import "../results/searchResults.scss";
import { SearchFilter } from "../../../consts/SearchFilter";
import {
  GenericSearchPage,
  SearchConfiguration,
  SearchResultsConfiguration,
  SearchSidebarConfiguration
} from "../GenericSearchPage";
import {DateFilterName, SearchFilterState} from "../../../hooks/slices/tenderSearchSlice";
import { ErrorPage } from "../../error";
import { SearchType } from "../../../consts/searchType";
import { Feature, FeatureName } from "../../../consts/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { PublicationDateToSortBy } from "../../../consts/publicationDateToSortBy";
import { TenderCardConfiguration } from "../results/body/TenderCard";
import { FeatureUtil } from "../../../utils/features";
import { SearchFilterUtil } from "../../../utils/searchFilters";
import { useTranslation } from "react-i18next";
import { SortBy } from "../../../consts/SortBy";
import {useAppSelector} from "../../../app/hooks";
import {SavedSearchUtil} from "../../../utils/savedsearches";
import {RaiseSavedSearches} from "../../../hooks/raiseSavedSearches";
import {DateFilterUtil} from "../../../utils/dateFilters";
import hasFeature = FeatureUtil.hasFeature;

export const AwardsSearchPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const savedSearchesState = RaiseSavedSearches();
  const savedSearchNames = SavedSearchUtil.getSavedSearchNames(savedSearchesState, t, i18n.language);

  const preSelectedFilters: SearchFilter[] = [
    SearchFilter.HAS_CONTRACT_AWARD_NOTICE,
    SearchFilter.HAS_OPENING_REPORT,
    SearchFilter.FIELD_GROUP_AWARD_COMPANY_NAMES,
    SearchFilter.FIELD_GROUP_BID_COMPANY_NAMES,
  ];
  const hiddenFilters: SearchFilter[] = getHiddenFilters();
  const lockedFilters: SearchFilter[] = [...hiddenFilters];
  const tenderCardConfiguration: TenderCardConfiguration = {
    showProgress: false,
    showDescription: true,
    showCompanies: true,
    showCueHighlights: true,
    showQueryHighlights: false,
    showAddendumHighlights: true, //this also triggers query highlights actually
    showLatestPublicationTypeInUpperRightCorner: true,
    showPublicationDetails: true,
    showTags: true,
    showLeadsIcon: true,
    showFollowIcon: false,
    showDeleteOpportunityIcon: false,
    showAwardDateInsteadOfDeadline: true,
    showTenderAISummaryButton: hasFeature(features, FeatureName.AI_FILE_ANALYSIS),
  };
  const searchViewConfiguration: SearchResultsConfiguration = {
    showTenderUuidFilterCategories: true,
    showExportButton: true,
    showResponseTime: true,
    showFilterButtons: true,
    showLabelAggregations: true,
    showCueAggregations: true,
    hiddenFilters: hiddenFilters,
    lockedFilters: lockedFilters,
  };
  const initialFilterState: SearchFilterState = {
    query: "", //this is overwritten if there is a previous input in the store
    page: 0,
    pageSize: 10,
    sortBy: SortBy.DATE,
    publicationDateToSortBy: PublicationDateToSortBy.AWARD_DATE_DESC,
    selectedFilters: preSelectedFilters,
    savedSearches: savedSearchNames
  };
  const searchSidebarConfiguration: SearchSidebarConfiguration = {
    queryType: SearchType.AWARDS,
    queryPlaceHolder: t("searchFilterTags.search"),
    showLabels: true,
    hiddenFilters: hiddenFilters,
    lockedFilters: lockedFilters,
    showPublicationDateFilters: true,
    showAwardOrOpeningReportDateFilters: true,
  };
  const searchConfiguration: SearchConfiguration = {
    sidebar: searchSidebarConfiguration,
    info: searchViewConfiguration,
    tenderCard: tenderCardConfiguration,
  };
  if (features !== undefined && !FeatureUtil.hasFeature(features, FeatureName.AWARDS_TAB)) {
    return <ErrorPage errorCode={403}></ErrorPage>;
  } else
    return (
      <GenericSearchPage initialSearchFilterState={initialFilterState} searchConfiguration={searchConfiguration} />
    );
};

function getHiddenFilters(): SearchFilter[]{
    const hiddenFilters: SearchFilter[] = [...SearchFilterUtil.getPublicationTypeFilters(), SearchFilter.HAS_AWARDS];
    return hiddenFilters;
}



