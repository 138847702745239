import {ResourceKey} from "i18next";

export const DateFilters: ResourceKey ={

	PUBLICATION_DATE_EVERYTHING: "Alles",
	PUBLICATION_DATE_YESTERDAY: "Meest recent",
	PUBLICATION_DATE_LAST_WEEK: "Laatste week",
	PUBLICATION_DATE_LAST_MONTH: "Laatste maand",
	PUBLICATION_DATE_LAST_TWO_MONTHS: "Laatste twee maanden",
	PUBLICATION_DATE_LAST_YEAR: "Laatste jaar",
	PUBLICATION_DATE_CUSTOM: "Custom",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING: "Alles",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY: "Meest recent",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK: "Laatste week",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH: "Laatste maand",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR: "Laatste jaar",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM: "Custom",
	ESTIMATED_RENEWAL_DATE_NEXT_MONTH: "Volgende maand",
	ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS: "Drie maanden",
	ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS: "Zes maanden",
	ESTIMATED_RENEWAL_DATE_NEXT_YEAR: "Volgend jaar",
	ESTIMATED_RENEWAL_DATE_ALL: "Alles",
	ESTIMATED_RENEWAL_DATE_CUSTOM: "Custom",
	DEADLINE_EVERYTHING: "Alles",
	DEADLINE_NOT_YET_EXPIRED: "Nog niet verstreken",
	DEADLINE_IN_7_DAYS: "7 dagen",
	DEADLINE_IN_14_DAYS: "14 dagen",
	DEADLINE_IN_1_MONTH: "1 maand",
	DEADLINE_IN_2_MONTHS: "2 maanden",
	DEADLINE_CUSTOM: "Custom",

};
