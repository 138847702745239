import {Feature, FeatureName} from "../consts/features";
import {FeatureUtil} from "./features";
import {DateUtil} from "./date";
import moment, {Moment} from "moment";
import {SearchType} from "../consts/searchType";
import {DateFilter, DateFilterName, DateFilterType} from "../hooks/slices/tenderSearchSlice";
import {DefaultSearchSettings} from "../hooks/slices/userSlice";

export namespace DateFilterUtil {

  function today() {
    return DateUtil.getDateString(DateUtil.today());
  }

  function todayPlusDays(days: number) {
    return DateUtil.getDateString(DateUtil.todayPlusDays(days));
  }

  function todayMinusDays(days: number) {
    return DateUtil.getDateString(DateUtil.todayMinusDays(days));
  }

  function todayPlusMonths(months: number) {
    return DateUtil.getDateString(DateUtil.todayPlusMonths(months));
  }

  function todayMinusMonths(months: number) {
    return DateUtil.getDateString(DateUtil.todayMinusMonths(months));
  }

  function todayMinusYears(years: number) {
    return DateUtil.getDateString(DateUtil.todayMinusYears(years));
  }

  function todayPlusYears(years: number) {
    return DateUtil.getDateString(DateUtil.todayPlusYears(years));
  }

  function startOfYear(year: number) {
    return DateUtil.getDateTimeString(moment(new Date(year, 0, 1)));
  }

  function startOfPreviousWeek() {
    return DateUtil.getDateString(DateUtil.getStartOfPreviousWeek());
  }

  function endOfPreviousWeek() {
    return DateUtil.getDateString(DateUtil.getEndOfPreviousWeek());
  }

  export function findByName(name: DateFilterName): DateFilter {
    return getByName(name, getAllDateFilters());
  }

  function getByName(name: DateFilterName, filters: DateFilter[]): DateFilter {
    return filters.find((f) => f.name === name) as DateFilter;
  }

  function getBySuffix(suffix: string, filters: DateFilter[]){
    return filters.find((f) => f.name.endsWith(suffix)) as DateFilter;
  }

  export function getAllDateFilters(): DateFilter[] {
    let filters: DateFilter[] = [];
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_EVERYTHING,
      startDate: undefined,
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_YESTERDAY,
      startDate: todayMinusDays(1),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_LAST_WEEK,
      startDate: todayMinusDays(7),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_LAST_MONTH,
      startDate: todayMinusMonths(1),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_LAST_YEAR,
      startDate: todayMinusYears(1),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_LAST_TWO_MONTHS,
      startDate: todayMinusMonths(2),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_PREVIOUS_WEEK_ONLY,
      startDate: startOfPreviousWeek(),
      endDate: endOfPreviousWeek(),
    });
    filters.push({
      type: DateFilterType.PUBLICATION_DATE,
      name: DateFilterName.PUBLICATION_DATE_CUSTOM,
      startDate: undefined,
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.DEADLINE,
      name: DateFilterName.DEADLINE_EVERYTHING,
      startDate: undefined,
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.DEADLINE,
      name: DateFilterName.DEADLINE_NOT_YET_EXPIRED,
      startDate: today(),
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.DEADLINE,
      name: DateFilterName.DEADLINE_IN_7_DAYS,
      startDate: today(),
      endDate: todayPlusDays(7),
    });
    filters.push({
      type: DateFilterType.DEADLINE,
      name: DateFilterName.DEADLINE_IN_14_DAYS,
      startDate: today(),
      endDate: todayPlusDays(14),
    });
    filters.push({
      type: DateFilterType.DEADLINE,
      name: DateFilterName.DEADLINE_IN_1_MONTH,
      startDate: today(),
      endDate: todayPlusMonths(1),
    });
    filters.push({
      type: DateFilterType.DEADLINE,
      name: DateFilterName.DEADLINE_IN_2_MONTHS,
      startDate: today(),
      endDate: todayPlusMonths(2),
    });
    filters.push({
      type: DateFilterType.DEADLINE,
      name: DateFilterName.DEADLINE_CUSTOM,
      startDate: undefined,
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
      name: DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING,
      startDate: undefined,
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
      name: DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY,
      startDate: todayMinusDays(1),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
      name: DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK,
      startDate: todayMinusDays(7),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
      name: DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_PREVIOUS_WEEK_ONLY,
      startDate: startOfPreviousWeek(),
      endDate: endOfPreviousWeek(),
    });
    filters.push({
      type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
      name: DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH,
      startDate: todayMinusMonths(1),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
      name: DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR,
      startDate: todayMinusYears(1),
      endDate: today(),
    });
    filters.push({
      type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
      name: DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM,
      startDate: undefined,
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.ESTIMATED_RENEWAL_DATE,
      name: DateFilterName.ESTIMATED_RENEWAL_DATE_ALL,
      startDate: undefined,
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.ESTIMATED_RENEWAL_DATE,
      name: DateFilterName.ESTIMATED_RENEWAL_DATE_NEXT_MONTH,
      startDate: todayPlusMonths(1),
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.ESTIMATED_RENEWAL_DATE,
      name: DateFilterName.ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS,
      startDate: todayPlusMonths(3),
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.ESTIMATED_RENEWAL_DATE,
      name: DateFilterName.ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS,
      startDate: todayPlusMonths(6),
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.ESTIMATED_RENEWAL_DATE,
      name: DateFilterName.ESTIMATED_RENEWAL_DATE_NEXT_YEAR,
      startDate: todayPlusYears(1),
      endDate: undefined,
    });
    filters.push({
      type: DateFilterType.ESTIMATED_RENEWAL_DATE,
      name: DateFilterName.ESTIMATED_RENEWAL_DATE_CUSTOM,
      startDate: undefined,
      endDate: undefined,
    });
    return filters;
  }

  export function select(filter: DateFilter, selectedFilters: DateFilter[]): DateFilter[] {
    return selectedFilters.filter((f) => f.type !== filter.type).concat(filter);
  }

  export function getDefaultSelectedDateFilter(
    relevantDateFilters: DateFilter[],
    searchType: SearchType | undefined
  ): DateFilter {
        if(searchType === SearchType.OPPORTUNITIES){ return getBySuffix("_YESTERDAY", relevantDateFilters);}
    else if(searchType === SearchType.FRAMEWORKS) {return getBySuffix("_NEXT_MONTH", relevantDateFilters);}
    return getBySuffix("_EVERYTHING", relevantDateFilters);
  }

  export function getEarliestStartDateForUser(features: Feature[] | undefined): Moment | undefined {
    let searchRangeLookBackInDays: number | undefined = FeatureUtil.getFeatureLimit(
      features,
      FeatureName.SEARCH_RANGE_DAYS
    );
    if (searchRangeLookBackInDays) {
      //weirdly !== undefined doesn't work here
      if (searchRangeLookBackInDays === 365) return DateUtil.todayMinusYears(1);
      else if (searchRangeLookBackInDays === 31) return DateUtil.todayMinusMonths(2);
      else return DateUtil.todayMinusDays(searchRangeLookBackInDays);
    } else return undefined;
  }

  export function getRelevantDateFilters(
    type: DateFilterType,
    earliestStartDate: Moment | undefined,
    searchType: SearchType | undefined
  ) {
    let relevantFilters: DateFilter[] = getAllDateFilters().filter((f) => f.type === type);
    let filtersUnlimited = earliestStartDate === undefined || searchType === SearchType.SHORTLIST;
    let alwaysHiddenFilters: DateFilterName[] = [
        DateFilterName.PUBLICATION_DATE_PREVIOUS_WEEK_ONLY,
        DateFilterName.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_PREVIOUS_WEEK_ONLY];
    if (searchType === SearchType.OPPORTUNITIES) {
      let dateFiltersToIgnore: DateFilterName[] = [
        DateFilterName.PUBLICATION_DATE_EVERYTHING,
        DateFilterName.PUBLICATION_DATE_LAST_YEAR,
        DateFilterName.PUBLICATION_DATE_CUSTOM,
      ];
      relevantFilters = relevantFilters.filter((f) => !dateFiltersToIgnore.includes(f.name));
    }
    if (!filtersUnlimited) {
      relevantFilters = relevantFilters.filter((f) => earliestStartDate?.isSameOrBefore(f.startDate));
    }
    return relevantFilters.filter((f) => !alwaysHiddenFilters.includes(f.name));
  }

  // This adds the 'EVERYTHING' filter at the top if present, sorts the filters by ascending range, and finally adds the custom filter at the end.
  export function sortDateFilters(filters: DateFilter[], selectedFilter: DateFilter | undefined): DateFilter[] {
    if (filters.length === 0) return filters;
    let sortedFilters: DateFilter[] = [];
    let everythingFilter = filters.find((f) => isDateRangeEverythingFilter(f));
    if (everythingFilter !== undefined) sortedFilters.push(everythingFilter); //should always be at the beginning
    let ascendingDateRangeFilters: DateFilter[] = filters
      .filter((f) => !isDateRangeCustomFilter(f) && !isDateRangeEverythingFilter(f))
      .sort((a, b) => {
        let aDate = getDateRangeFilterPseudoDayRange(a);
        let bDate = getDateRangeFilterPseudoDayRange(b);
        if (aDate === undefined || bDate === undefined) return 0;
        return aDate - bDate;
      });
    sortedFilters.push(...ascendingDateRangeFilters);
    let customFilter = filters.find((f) => isDateRangeCustomFilter(f));
    if (customFilter !== undefined) sortedFilters.push(customFilter); //should always be at the end
    return sortedFilters;
  }

  function getDateRangeFilterPseudoDayRange(f: DateFilter): number {
    if (isDateRangeYesterdayFilter(f)) return 1;
    if (isDateRangeLastWeekFilter(f)) return 7;
    if (isDateRangeLastMonthFilter(f)) return 31;
    if (isDateRangeLastTwoMonthsFilter(f)) return 62;
    if (isDateRangeLastYearFilter(f)) return 365;
    if (isDateRangeEverythingFilter(f)) return 1000;
    return 0;
  }

  function isDateRangeEverythingFilter(f: DateFilter): boolean {
    return f.name.endsWith("_EVERYTHING");
  }

  function isDateRangeCustomFilter(f: DateFilter): boolean {
    return f.name.endsWith("_CUSTOM");
  }

  function isDateRangeYesterdayFilter(f: DateFilter): boolean {
    return f.name.endsWith("_YESTERDAY");
  }

  function isDateRangeLastWeekFilter(f: DateFilter): boolean {
    return f.name.endsWith("_LAST_WEEK");
  }

  function isDateRangeLastPreviousWeekOnlyFilter(f: DateFilter): boolean {
    return f.name.endsWith("_PREVIOUS_WEEK_ONLY");
  }

  function isDateRangeLastMonthFilter(f: DateFilter): boolean {
    return f.name.endsWith("_LAST_MONTH");
  }

  function isDateRangeLastTwoMonthsFilter(f: DateFilter): boolean {
    return f.name.endsWith("_LAST_TWO_MONTHS");
  }

  function isDateRangeLastYearFilter(f: DateFilter): boolean {
    return f.name.endsWith("_LAST_YEAR");
  }
}
