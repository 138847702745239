import React from 'react';
import styles from './index.module.scss';
import { FlagIcon } from '../../../flags/flags';
import { Country } from '../../../../consts/countries';
import {useTranslation} from "react-i18next";


export interface ItemProps extends React.HTMLAttributes<HTMLDivElement> {
    companyName: string;
    companyNumber: string;
    street: string;
    postalCode: string;
    city: string;   
    country: Country;
    sharedProjects?: number | null;
    className?: string;
    handleClick?: () => void;
}

export const Item: React.FC<ItemProps> = ({
    children,
    companyName,
    companyNumber,
    street,
    postalCode,
    city,
    country,
    sharedProjects,
    className='',
    handleClick=() => {},
    ...props
}) => {
    let {t} = useTranslation();
    return (
        <div
            className={`${styles['container']} ${className}}`}
            onClick={handleClick}
            {...props}
        >
            <h3>
                <FlagIcon country={country}/>
                { companyName } 
            </h3>
            <div> { companyNumber } </div>
            <div> { `${street}, ${postalCode} ${city}` } </div>
            {sharedProjects != null && sharedProjects > 0 && <div>{t("companyPage.shared_projects_title") + ": "  + sharedProjects}</div>}
            { children }
        </div>
    );
}