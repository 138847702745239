import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { FetchArgs } from "@reduxjs/toolkit/query";
import { UserInteractionType } from "../../consts/UserInteractionType";
import { apiSlice } from "./apiSlice";
import { Label, LabelCategory } from "../../types/label";
import { UserLabelCategoriesState } from "./labelSlice";
import {DateFilterName} from "./tenderSearchSlice";

//TODO: lastName and email are not needed in the state store and can be removed here
//      when they are removed, the accountSetting/account/generic.tsx file needs to be adapted
export interface UserState {
    uuid?: string;
    clientUuid?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    locale?: string;
    activityStatus?: string;
    isAdmin?: boolean;
    labelCategories?: LabelCategory[];
    userInteractions?: UserInteraction[];
    companyPageInteractions?: CompanyPageInteraction[];
    defaultSearchSettings?: DefaultSearchSettings;
}

const initialState: UserState = {};

export const userSlice = createSlice({
    initialState,
    name: "user",
    reducers: {
        updateUser(state, action: PayloadAction<UserResponse>) {
            state.uuid = action.payload.uuid;
            state.clientUuid = action.payload.clientUuid;
            state.email = action.payload.email;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.locale = action.payload.locale;
            state.activityStatus = action.payload.activityStatus;
            state.isAdmin = action.payload.isAdmin;
            state.labelCategories = action.payload.labelCategories;
            state.userInteractions = action.payload.userInteractions;
            state.companyPageInteractions = action.payload.companyPageInteractions;
            state.defaultSearchSettings = action.payload.defaultSearchSettings;
        },
        updateUserEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
        },
        updateDefaultSearchSettings(state, action: PayloadAction<DefaultSearchSettings>) {
            state.defaultSearchSettings = action.payload;
        },
        updateActivityStatus(state, action: PayloadAction<UserActivityStatusResponse>) {
            state.activityStatus = action.payload.activityStatus;
        }, updateUserLabelCategories(state, action: PayloadAction<UserLabelCategoriesState>) {
            state.labelCategories = action.payload.labelCategories;
        }, addLabelCategory(state, action: PayloadAction<LabelCategory>) {
            if (state.labelCategories != null) state.labelCategories = [...state.labelCategories, action.payload];
            else state.labelCategories = [action.payload];
        }, addLabelToDefaultCategoryIfNew(state, action: PayloadAction<Label>) {
            let labelId = action.payload.id;
            let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(state.labelCategories));
            let labelAlreadyPresent = false;
            for (const c of newLabelCategories) {
                let labelInCategory = c.labels.filter(l => l.id === labelId).length > 0;
                if (labelInCategory) {
                    labelAlreadyPresent = true;
                    break;
                }
            }
            // if (labelName === "") labelAlreadyPresent = true;
            if(!labelAlreadyPresent){
                for (const c of newLabelCategories) {
                    if (c.isDefault) {
                        c.labels = [...c.labels, action.payload]
                    }
                }
            } state.labelCategories = newLabelCategories;
        }, incrementLabelCount(state, action: PayloadAction<Label>) {
            let labelId = action.payload.id;
            let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(state.labelCategories));
            for (const c of newLabelCategories) {
                    for(const l of c.labels){
                        if (l.id === labelId && l.count !== undefined) {
                            l.count = l.count + 1;
                        }
                    }
            } state.labelCategories = newLabelCategories;
        }, decrementLabelCount(state, action: PayloadAction<Label>) {
            let labelId = action.payload.id;
            let newLabelCategories: LabelCategory[] = JSON.parse(JSON.stringify(state.labelCategories));
            for (const c of newLabelCategories) {
                for(const l of c.labels){
                    if (l.id === labelId && l.count !== undefined) {
                        l.count = l.count - 1;
                    }
                }
            } state.labelCategories = newLabelCategories;
        }, updateUserInteractions(state, action: PayloadAction<UserInteraction[]>) {
            state.userInteractions = action.payload;
        },
        addUserInteraction(state, action: PayloadAction<UserInteraction>) {
            if (state.userInteractions)
                state.userInteractions = [...state.userInteractions, action.payload];
            else state.userInteractions = [action.payload];
        },
        removeUserInteraction(state, action: PayloadAction<UserInteraction>) {
            if (state.userInteractions) {
                state.userInteractions = state.userInteractions.filter(
                    (i) => i.uuid !== action.payload.uuid || i.interactionType !== action.payload.interactionType
                );
            }
        },updateCompanyPageInteractions(state, action: PayloadAction<CompanyPageInteraction[]>) {
            state.companyPageInteractions = action.payload;
        },
        addCompanyPageInteraction(state, action: PayloadAction<CompanyPageInteraction>) {
            if (state.companyPageInteractions)
                state.companyPageInteractions = [...state.companyPageInteractions, action.payload];
            else state.companyPageInteractions = [action.payload];
        },
        removeCompanyPageInteraction(state, action: PayloadAction<{companyPageUuid: string}>) {
            if (state.companyPageInteractions) {
                state.companyPageInteractions = state.companyPageInteractions.filter(
                    (i) => i.companyPageUuid !== action.payload.companyPageUuid
                );
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.uuid = initialState.uuid;
            state.clientUuid = initialState.clientUuid;
            state.email = initialState.email;
            state.firstName = initialState.firstName;
            state.lastName = initialState.lastName;
            state.locale = initialState.locale;
            state.activityStatus = initialState.activityStatus;
            state.isAdmin = initialState.isAdmin;
            state.labelCategories = initialState.labelCategories;
            state.userInteractions = initialState.userInteractions;
            state.companyPageInteractions = initialState.companyPageInteractions;
        });
    },
});

export const {updateUser, updateUserEmail, updateActivityStatus,updateDefaultSearchSettings,addLabelCategory,
    updateUserLabelCategories, addLabelToDefaultCategoryIfNew, incrementLabelCount, decrementLabelCount,
addUserInteraction, removeUserInteraction, updateUserInteractions, addCompanyPageInteraction,
    removeCompanyPageInteraction} = userSlice.actions;

/*
  API connection stuff
*/
export interface PutUserParams {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    locale: string;
}

function UserPutBody(putUserParams: PutUserParams) {
    return {
        firstName: putUserParams.firstName,
        lastName: putUserParams.lastName,
        email: putUserParams.email,
        locale: putUserParams.locale,
    };
}

export interface GetUserParams {
    userID: string;
}

export interface UserInteractionInput {
    userUuid: string;
    interaction: UserInteraction;
}

export interface UserInteractionsOutput {
    userInteractions: UserInteraction[];
}

export interface UserInteraction {
    uuid: string;
    interactionType: UserInteractionType;
}

export interface CompanyPageInteraction {
    companyPageUuid: string;
    companyPageVatNumber: string;
    companyPageName: string;
}

export interface DefaultSearchSettings{
    publicationDate?: DateFilterName;
}

export interface UserResponse {
    uuid: string;
    clientUuid: string;
    email: string;
    firstName: string;
    lastName: string;
    locale: string;
    activityStatus: string;
    isAdmin: boolean;
    userInteractions: UserInteraction[];
    companyPageInteractions: CompanyPageInteraction[];
    labelCategories: LabelCategory[];
    defaultSearchSettings?: DefaultSearchSettings;
}

export interface UserActivityStatusResponse {
    activityStatus: string;
}


const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getUser: build.query<UserResponse, GetUserParams>({
            query: (params: GetUserParams) => {
                return {
                    url: `/shaggy/user`,
                };
            },
        }),
        getUserActivityStatus: build.query<UserActivityStatusResponse, void>({
            query: () => {
                return {
                    url: `/shaggy/user/activity_status`,
                };
            },
        }),
        putUser: build.mutation<UserResponse, PutUserParams>({
            query: (body: PutUserParams) => {
                return {
                    url: `shaggy/user`,
                    method: 'PUT',
                    body: UserPutBody(body)
                }
            }
        }),
        updateDefaultSearchSettings: build.mutation<void, DefaultSearchSettings>({
            query: (body: DefaultSearchSettings) => {
                return {
                    url: `shaggy/user/default_search_settings`,
                    method: 'PUT',
                    body: body
                }
            }
        }),
        addUserInteraction: build.mutation<UserInteractionsOutput, UserInteractionInput>({
            query: (body: UserInteractionInput) => {
                let args: FetchArgs =  {
                    url: "/shaggy/user/user_interactions",
                    method: "POST",
                    body: body.interaction,
                };
                return args;
            },
        }),
        removeUserInteraction: build.mutation<UserInteractionsOutput, UserInteractionInput>({
            query: (body: UserInteractionInput) => {
                let args: FetchArgs =  {
                    url: "/shaggy/user/user_interactions",
                    method: "DELETE",
                    body: body.interaction,
                };
                return args;
            },
        }),
    }),
});

export const {
    useGetUserQuery,
    useGetUserActivityStatusQuery,
    useUpdateDefaultSearchSettingsMutation,
    usePutUserMutation,
    useAddUserInteractionMutation,
    useRemoveUserInteractionMutation,
} = extendedApiSlice;