import React from 'react'
import styles from './index.module.scss';
import {useLogMutation} from "../../../../hooks/slices/logSlice";

export  interface WhatIsTenderwolfButtonProps 
        extends React.HTMLAttributes<HTMLButtonElement> {
    text?: string;
    vatNumberOrCompanyUuid: string;
    tenderUuid: string;
}

export const WhatIsTenderwolfButton: React.FC<WhatIsTenderwolfButtonProps> = ({
    text = "What is Tenderwolf ?",
    ...props
}) => {

    const [log] = useLogMutation();
    const handleClick = () => {
        log({message : "<ACCELERATED_SIGNUP_ACTIVITY>About button clicked by " + props.vatNumberOrCompanyUuid + " while viewing tender " + props.tenderUuid});
        window.open("https://tenderwolf.com/", "_blank");
    }

    return (<button 
            className={styles["what-is-tenderwolf-button"]}
            onClick={handleClick}
            {...props}
        >
            { text }
    </button>);
}