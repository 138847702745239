import React from 'react'
import { useSearchParams } from 'react-router-dom';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {useLogMutation} from "../../../../hooks/slices/logSlice";

export  interface FollowYourCompetitorsButtonProps 
        extends React.HTMLAttributes<HTMLButtonElement> {
    hidden?: boolean;
    vatNumberOrCompanyUuid: string;
    tenderUuid: string;
}

export const FollowYourCompetitorsButton: React.FC<FollowYourCompetitorsButtonProps> = ({
    hidden = false,
    ...props
}) => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [log] = useLogMutation();
    function handleShowCompetitors() {
        log({message : "<ACCELERATED_SIGNUP_ACTIVITY>Competitors button clicked by " + props.vatNumberOrCompanyUuid  + " while viewing tender " + props.tenderUuid});
        searchParams.set("showCompetitors", "")
        navigate(`?${searchParams.toString()}`);
    }
    function handleHideCompetitors() {
        searchParams.delete("showCompetitors");
        navigate(`?${searchParams.toString()}`);
    }

    const [searchParams] = useSearchParams();
    let showCompetitors = searchParams.has("showCompetitors");
    
    var handleClick = () => {};
    var text = "";
    if (showCompetitors) {
        handleClick = handleHideCompetitors; 
        text = t("navigation.showTenderInformation");
    } else {
        handleClick = handleShowCompetitors;
        text = t("navigation.followYourCompetitors");
    }

    return (<button 
        className={`
            ${styles["follow-your-competitors-button"]}
            ${hidden ? styles["hidden"] : ""} 
            `}
        onClick={handleClick}
        {...props}
    >
        { text }
    </button>);
}