import { PublicationInformation, S3FileDocument, Tender } from "../types/tender";
import { TenderUtil } from "./tenders";
import { MultilingualFieldUtil } from "./multilingualfield";
import React from "react";
import {DateUtil} from "./date";
import {Moment} from "moment";

export namespace S3FileUtil {
  export function extractFileName(file: S3FileDocument) {
    if (file && file.s3Key) {
      const lastSlashIndex = file.s3Key.lastIndexOf("/");
      if (lastSlashIndex !== -1) {
        return file.s3Key.substring(lastSlashIndex + 1);
      }
    } else {
      return file.fileName;
    }
  }

  export function isArchiveFile(file: S3FileDocument) {
    let s3FileName = extractFileName(file);
    return (
      s3FileName != null && (s3FileName.endsWith(".zip") || s3FileName.endsWith(".rar") || s3FileName.endsWith(".7z"))
    );
  }

  export function getDownloadableFiles(tender: Tender): S3FileDocument[] {
    const files: S3FileDocument[] = tender.addenda ? [...tender.addenda] : [];
    const uniqueKeys = new Set();
    const undownloadableFileTypes = ["CONTENT_FILE_HUMAN_READABLE", "CONTENT_FILE_MACHINE_READABLE", "META_FILE"];
    TenderUtil.getPublicationInformationsSorted(tender).forEach((pubInfo) => {
      if (pubInfo.publicationInformation.files) {
        pubInfo.publicationInformation.files.forEach((file) => {
          if (file.s3Key != null && !undownloadableFileTypes.includes(file.type) && !uniqueKeys.has(file.s3Key)) {
            files.push(file);
            uniqueKeys.add(file.s3Key);
            //     distinctFileNames.push(file.fileName);
          }
        });
      }
    });
    return files;
  }

  export function filterSpecifications(files: S3FileDocument[]) {
    return files.filter(
      (file) =>
        file.type === "ADMINISTRATIVE_SPECIFICATIONS" ||
        file.type === "TECHNICAL_SPECIFICATIONS" ||
        file.type === "SELECTION_GUIDE"
    );
  }

  export function findMostRelevantSpecification(files: S3FileDocument[]): S3FileDocument | undefined {
    let specification: S3FileDocument | undefined = files.find((file) => file.type === "ADMINISTRATIVE_SPECIFICATIONS");
    if (!specification) specification = files.find((file) => file.type === "SELECTION_GUIDE");
    if (!specification) specification = files.find((file) => file.type === "TECHNICAL_SPECIFICATIONS");
    return specification;
  }

  export function fileIsSpecification(file: S3FileDocument) {
    return (
      file.type === "ADMINISTRATIVE_SPECIFICATIONS" ||
      file.type === "TECHNICAL_SPECIFICATIONS" ||
      file.type === "SELECTION_GUIDE"
    );
  }

  export function createArchiveFileName(tender: Tender, languageIso: string) {
    let fileName: string = MultilingualFieldUtil.translate(tender.title, languageIso).toLowerCase();
    fileName = fileName.replace(/[éêèë]/g, "e");
    fileName = fileName.replace(/[áâàä]/g, "e");
    fileName = fileName.replace(/[óôòö]/g, "e");
    fileName = fileName.replace(/[úûùü]/g, "e");
    fileName = fileName.replace(/[íîìï]/g, "e");
    fileName = fileName.replace(/[^a-z0-9\s]/g, "");
    let parts: string[] = fileName.split(" ").filter((part) => part.length > 0);
    if (parts.length > 12) parts = parts.slice(0, 12);
    return parts.join("_") + ".zip";
  }

  export function getOfficialPublicationFileUrl(
    publication: PublicationInformation,
    languageIso: string,
    source: string | undefined
  ): string | undefined {
    if (source && source.startsWith("https://www.boamp.fr")) return getBoampOfficialPublicationFileUrl(publication);
    else if(source && source.startsWith("https://ted.europa.eu")) return getTedOfficialPublicationFileUrl(publication, languageIso);
    else {
      let files: S3FileDocument[] = publication.files.filter(isOfficialPublicationFile);
      if (files.length > 1) files = filterFilesByLanguage(files, languageIso);
      return files.length > 0 ? getFileUrl(files[0].url) : undefined;
    }
  }

  //eg https://www.boamp.fr/telechargements/FILES/PDF/2025/01/25-4078.pdf
  function getBoampOfficialPublicationFileUrl(publication: PublicationInformation): string | undefined {
    if (publication.publicationDate == null) return undefined;
    let year = publication.publicationDate.split("-")[0]; //2025-01-17
    if (Number.parseInt(year) < 2024) return undefined;
    let month = publication.publicationDate.split("-")[1];
    if (month.length === 1) month = "0" + month;
    return "https://www.boamp.fr/telechargements/FILES/PDF/"+ year +"/"+month+"/"+publication.platformPublicationId+".pdf"
  }

  function getTedOfficialPublicationFileUrl(publication: PublicationInformation, languageIso: string): string | undefined {
    let platformPublicationId = publication.platformPublicationId.replace("_", "-").replace(/^0+/, '');
    return "https://ted.europa.eu/udl?uri=TED:NOTICE:" + platformPublicationId + ":PDF:" + languageIso + ":HTML";
  }

  export function getSourceUrl(publication: PublicationInformation){
    let officialPublicationUrl = publication.officialPublicationUrl;
    return checkValidOfficialPublicationUrl(officialPublicationUrl) ? officialPublicationUrl : undefined;
  }

  function checkValidOfficialPublicationUrl(url: string | null): boolean {
    return url !== null
        && !url.startsWith("https://enot.publicprocurement.be/enot-war")
        && !url.startsWith("https://eten.publicprocurement.be/etendering");
  }
  function isOfficialPublicationFile(file: S3FileDocument) {
    return (
      (file.type === "OFFICIAL_PUBLICATION_URL" || file.type === "CONTENT_FILE_HUMAN_READABLE") && file.url != null
    );
  }

  function filterFilesByLanguage(files: S3FileDocument[], languageIso: string): S3FileDocument[] {
    let filesWithCorrectLanguage = files.filter((file) => file.languageIso === languageIso);
    return filesWithCorrectLanguage.length > 0 ? filesWithCorrectLanguage : files;
  }

  function getFileUrl(url: string | undefined): string | undefined {
    if (!url) return undefined;
    return url.startsWith("https://s3-eu-west-1.amazonaws.com") ? translateS3UrlToGCP(url) : url;
  }

  function translateS3UrlToGCP(s3Url: string): string | undefined {
    const gcpProductionBaseUrl = "https://storage.googleapis.com/vbr-tenderwolf-p-csb-tw3";
    const gcpDevBaseUrl = "https://storage.googleapis.com/vbr-tenderwolf-d-csb-tw3";
    if (s3Url.startsWith("https://s3-eu-west-1.amazonaws.com/tw3s3-test"))
      return s3Url.replace("https://s3-eu-west-1.amazonaws.com/tw3s3-test", gcpDevBaseUrl);
    else return s3Url.replace("https://s3-eu-west-1.amazonaws.com/tw3s3", gcpProductionBaseUrl);
  }
}
