import React from "react";
import "../results/searchResults.scss";
import { SearchFilter } from "../../../consts/SearchFilter";
import {
  GenericSearchPage,
  SearchConfiguration,
  SearchResultsConfiguration,
  SearchSidebarConfiguration,
} from "../GenericSearchPage";
import {DateFilterName, SearchFilterState} from "../../../hooks/slices/tenderSearchSlice";
import { SearchType } from "../../../consts/searchType";
import { Feature, FeatureName } from "../../../consts/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { PublicationDateToSortBy } from "../../../consts/publicationDateToSortBy";
import { SearchFilterUtil } from "../../../utils/searchFilters";
import { TenderCardConfiguration } from "../results/body/TenderCard";
import { useTranslation } from "react-i18next";
import { SortBy } from "../../../consts/SortBy";
import { FeatureUtil } from "../../../utils/features";
import { ErrorPage } from "../../error";
import {DateFilterUtil} from "../../../utils/dateFilters";
import hasFeature = FeatureUtil.hasFeature;

export const FrameworksSearchPage: React.FC = () => {
  const { t } = useTranslation();
  let preSelectedFilters: SearchFilter[] = [
    SearchFilter.IS_FRAMEWORK_AGREEMENT_YES,
  ];
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const hiddenFilters: SearchFilter[] = getHiddenFilters();
  const lockedFilters: SearchFilter[] = [...hiddenFilters];
  const tenderCardConfiguration: TenderCardConfiguration = {
    showProgress: true,
    showEstimatedRenewalDate: true,
    showDescription: false,
    showCompanies: true,
    showCueHighlights: false,
    showQueryHighlights: false,
    showAddendumHighlights: true,
    showLatestPublicationTypeInUpperRightCorner: false,
    showPublicationDetails: true,
    showTags: true,
    showLeadsIcon: true,
    showFollowIcon: true,
    showDeleteOpportunityIcon: false,
    showTenderAISummaryButton: hasFeature(features, FeatureName.AI_FILE_ANALYSIS),
  };
  const searchViewConfiguration: SearchResultsConfiguration = {
    showExportButton: true,
    showResponseTime: true,
    showFilterButtons: true,
    showLabelAggregations: true,
    showCueAggregations: false,
    hiddenFilters: hiddenFilters,
    lockedFilters: lockedFilters,
  };
  const initialSearchFilterState: SearchFilterState = {
    query: "", //this is overwritten if there is a previous input in the store
    page: 0,
    pageSize: 10,
    sortBy: SortBy.DATE,
    publicationDateToSortBy: PublicationDateToSortBy.ESTIMATED_RENEWAL_DATE_ASC,
    selectedFilters: preSelectedFilters,
    selectedDateFilters: [DateFilterUtil.findByName(DateFilterName.ESTIMATED_RENEWAL_DATE_NEXT_MONTH)],
  };
  const searchSidebarConfiguration: SearchSidebarConfiguration = {
    queryType: SearchType.FRAMEWORKS,
    queryPlaceHolder: t("searchFilterTags.search"),
    showLabels: true,
    hiddenFilters: hiddenFilters,
    lockedFilters: lockedFilters,
    showSearchSectorFilter: true,
    showEstimatedRenewalDateFilters: true,
  };
  const searchConfiguration: SearchConfiguration = {
    sidebar: searchSidebarConfiguration,
    info: searchViewConfiguration,
    tenderCard: tenderCardConfiguration,
  };
  if (features !== undefined && !FeatureUtil.hasFeature(features, FeatureName.FRAMEWORKS_TAB)) {
    return <ErrorPage errorCode={403}></ErrorPage>;
  } else
    return (
      <GenericSearchPage
        initialSearchFilterState={initialSearchFilterState}
        searchConfiguration={searchConfiguration}
      />
    );
};

function getHiddenFilters(): SearchFilter[]{
    let hiddenFilters: SearchFilter[] = SearchFilterUtil.getFieldGroupFilters();
    //Formuliertype filters
    hiddenFilters.push(SearchFilter.HAS_PRIOR_INFORMATION_NOTICE)
    hiddenFilters.push(SearchFilter.HAS_CONTRACT_NOTICE)
    hiddenFilters.push(SearchFilter.HAS_CONTRACT_AWARD_NOTICE)
    hiddenFilters.push(SearchFilter.HAS_DESIGN_CONTEST)
    hiddenFilters.push(SearchFilter.HAS_CORRIGENDA)
    hiddenFilters.push(SearchFilter.HAS_AWARD_MODIFICATIONS)
    hiddenFilters.push(SearchFilter.HAS_OPENING_REPORT)
    hiddenFilters.push(SearchFilter.HAS_MUNICIPAL_DECISION)
    return hiddenFilters;
}




