import React from "react";
import "../results/searchResults.scss";
import { SearchFilter } from "../../../consts/SearchFilter";
import {
  GenericSearchPage,
  SearchConfiguration,
  SearchResultsConfiguration,
  SearchSidebarConfiguration,
} from "../GenericSearchPage";
import {DateFilterName, SearchFilterState} from "../../../hooks/slices/tenderSearchSlice";
import { ErrorPage } from "../../error";
import { SearchType } from "../../../consts/searchType";
import { Feature, FeatureName } from "../../../consts/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { PublicationDateToSortBy } from "../../../consts/publicationDateToSortBy";
import { FeatureUtil } from "../../../utils/features";
import { SearchFilterUtil } from "../../../utils/searchFilters";
import { TenderCardConfiguration } from "../results/body/TenderCard";
import { SavedSearchUtil } from "../../../utils/savedsearches";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SortBy } from "../../../consts/SortBy";
import { RaiseSavedSearches } from "../../../hooks/raiseSavedSearches";
import {DateFilterUtil} from "../../../utils/dateFilters";
import {DefaultSearchSettings} from "../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../hooks/raiseUser";
import hasFeature = FeatureUtil.hasFeature;

export const OpportunitiesSearchPage: React.FC = () => {
  const { t, i18n } = useTranslation();

  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const defaultSearchSettings : DefaultSearchSettings | undefined = RaiseUser().user?.defaultSearchSettings;
  const savedSearchesState = RaiseSavedSearches();
  const savedSearchNames = SavedSearchUtil.getSavedSearchNames(savedSearchesState, t, i18n.language);
  const userCanSearchAddenda: boolean = FeatureUtil.hasFeature(features, FeatureName.SEARCH_ADDENDA);
  const userHasProfileThatSearchesAddenda: boolean = SavedSearchUtil.hasProfileWhichSearchesAddenda(
    savedSearchesState.savedSearches?.tenderSearches
  );
  const opportunitiesLimit: number | undefined = FeatureUtil.getFeatureLimit(features, FeatureName.UPDATE_MAIL_OPPORTUNITIES);
  const userHasLimitedDateRange = opportunitiesLimit && opportunitiesLimit > 0 && FeatureUtil.hasFeature(features, FeatureName.UPDATE_MAIL_WEEKLY);
  const hiddenFilters: SearchFilter[] = getHiddenFilters(opportunitiesLimit);
  const lockedFilters: SearchFilter[] = [...hiddenFilters];
  const selectedFilters: SearchFilter[] = getSelectedFilters(
    userCanSearchAddenda,
    userHasProfileThatSearchesAddenda
  );
  //since the opportunities page is the default page, we need to redirect if the user does not have access to the opportunities page
  const location = useLocation();
  const navigate = useNavigate();
  if (
    location.pathname.startsWith("/opportunities") &&
    features !== undefined &&
    !FeatureUtil.hasFeature(features, FeatureName.OPPORTUNITIES_TAB)
  ) {
    if (FeatureUtil.hasFeature(features, FeatureName.LEADS_TAB)) navigate("/shortlist");
    else navigate("/account");
  }
  let selectedPublicationDate = defaultSearchSettings?.publicationDate ? defaultSearchSettings.publicationDate : DateFilterName.PUBLICATION_DATE_YESTERDAY;
  if (userHasLimitedDateRange) selectedPublicationDate = DateFilterName.PUBLICATION_DATE_PREVIOUS_WEEK_ONLY;
  const tenderCardConfiguration: TenderCardConfiguration = {
    showProgress: false,
    showDescription: true,
    showCompanies: false,
    showCueHighlights: true,
    showQueryHighlights: false,
    showAddendumHighlights: userCanSearchAddenda,
    showLatestPublicationTypeInUpperRightCorner: true,
    showPublicationDetails: true,
    showTags: true,
    showLeadsIcon: true,
    showFollowIcon: true,
    showDeleteOpportunityIcon: true,
    showProfiles: true,
    showTenderAISummaryButton: hasFeature(features, FeatureName.AI_FILE_ANALYSIS),
  };
  const initialSearchFilterState: SearchFilterState = {
    query: "", //this is overwritten if there is a previous input in the store
    page: 0,
    pageSize: opportunitiesLimit && opportunitiesLimit < 10 ? opportunitiesLimit : 10,
    sortBy: SortBy.RELEVANCE,
    publicationDateToSortBy: PublicationDateToSortBy.PUBLICATION_DATE_DESC,
    selectedFilters: selectedFilters,
    savedSearches: savedSearchNames,
    selectedDateFilters: [DateFilterUtil.findByName(selectedPublicationDate)]
  };
  const searchViewConfiguration: SearchResultsConfiguration = {
    showResponseTime: true,
    showFilterButtons: true,
    showLabelAggregations: true,
    showCueAggregations: true,
    maxPages: opportunitiesLimit ? Math.floor(opportunitiesLimit / initialSearchFilterState.pageSize) : undefined,
    hiddenFilters: hiddenFilters,
    lockedFilters: lockedFilters,
    blockDateFilterButtons: true,
    showExportButton: true
  };
  const searchSidebarConfiguration: SearchSidebarConfiguration = {
    queryType: SearchType.OPPORTUNITIES,
    queryPlaceHolder: t("searchFilterTags.search"),
    showLabels: true,
    showAccreditations: true,
    hiddenFilters: hiddenFilters,
    lockedFilters: lockedFilters,
    showPublicationDateFilters: !userHasLimitedDateRange,
  };
  const searchConfiguration: SearchConfiguration = {
    sidebar: searchSidebarConfiguration,
    info: searchViewConfiguration,
    tenderCard: tenderCardConfiguration,
  };

  if (features && !FeatureUtil.hasFeature(features, FeatureName.OPPORTUNITIES_TAB)) {
    return <ErrorPage errorCode={403}></ErrorPage>;
  } else
    return (
      <GenericSearchPage
        initialSearchFilterState={initialSearchFilterState}
        searchConfiguration={searchConfiguration}
      />
    );
};

function getHiddenFilters(opportunitiesLimit: number | undefined): SearchFilter[]{
    const hiddenFilters: SearchFilter[] = [];
    hiddenFilters.push(SearchFilter.DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE);
    let publicationTypeFilters = SearchFilterUtil.getPublicationTypeFilters();
    let filtersToRemove = SearchFilterUtil.getPublicationTypeFiltersForSavedSearch();
    publicationTypeFilters = publicationTypeFilters.filter(filter => !filtersToRemove.includes(filter));
    publicationTypeFilters.forEach(f => hiddenFilters.push(f));
    return hiddenFilters;
}

function getSelectedFilters(userCanSearchAddenda: boolean,
                            userHasProfileThatSearchesAddenda: boolean): SearchFilter[]{
    const selectedFilters: SearchFilter[] = [] //deadline not yet expired etc are added through the FilterGroups
    if(userCanSearchAddenda && userHasProfileThatSearchesAddenda) selectedFilters.push(SearchFilter.FIELD_GROUP_ADDENDA);
    return selectedFilters;
}
