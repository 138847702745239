import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

export const AISummary: React.FC<{ summary: string, showTitle: boolean }> & {} = ({ summary, showTitle }) => {
  let { t } = useTranslation();
  return (
    <div  className={styles['specification-summary']}>
        {showTitle && <h1>{t("publications.specificationSummaryTitle")}</h1>}
      <pre>{summary}</pre>
      <p className={styles['caveat']}>{t("publications.aiCaveat")}</p>
    </div>
  );
};
