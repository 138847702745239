import {ResourceKey} from "i18next";

export const SearchFilters: ResourceKey ={
	FIELD_GROUP_TITLE: "Titel",
	FIELD_GROUP_DESCRIPTION: "Beschrijving",
	FIELD_GROUP_SPECIFICATIONS: "Specificaties",
	FIELD_GROUP_ADDENDA: "Bijlagen",
    FIELD_GROUP_AWARD_COMPANY_NAMES:"Winnaars",
    FIELD_GROUP_BID_COMPANY_NAMES:"Deelnemers",
	COUNTRY_BE: "België",
	COUNTRY_NL: "Nederland",
	COUNTRY_FR: "Frankrijk",
	COUNTRY_LU: "Luxemburg",
	LANGUAGE_NL: "Nederlands",
	LANGUAGE_FR: "Frans",
	LANGUAGE_DE: "Duits",
	LANGUAGE_EN: "Engels",
	HAS_PRIOR_INFORMATION_NOTICE: "Vooraankondiging",
	HAS_CONTRACT_NOTICE: "Aankondiging",
	HAS_CONTRACT_AWARD_NOTICE: "Gunning",
	HAS_DESIGN_CONTEST: "Ontwerpwedstrijd",
	HAS_CORRIGENDA: "Rectificatie",
	HAS_AWARD_MODIFICATIONS: "Rectificatie van gunning",
	HAS_OPENING_REPORT: "Rapport van opening",
	HAS_MUNICIPAL_DECISION: "Gemeenteraadsverslag",
	CONTRACT_TYPE_SERVICES: "Diensten",
	CONTRACT_TYPE_WORKS: "Werken",
	CONTRACT_TYPE_SUPPLIES: "Leveringen",
	CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION: "Internationale organisatie",
	CONTRACTING_AUTHORITY_TYPE_MINISTRY: "Ministerie",
	CONTRACTING_AUTHORITY_TYPE_UTILITIES: "Nutsbedrijven",
	CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC: "Publiekrechtelijke instelling",
	CONTRACTING_AUTHORITY_TYPE_RA: "Regionale instantie",
	CONTRACTING_AUTHORITY_TYPE_LA: "Lokale overheid",
	CONTRACTING_AUTHORITY_TYPE_CGA: "Centrale overheidsinstantie",
	CONTRACTING_AUTHORITY_TYPE_DEF_CONT: "Contractant op defensiegebied",
	CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT: "Overheidsonderneming",
	CONTRACTING_AUTHORITY_TYPE_REGIONAL_AGENCY: "Regionaal orgaan",
	CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY: "Nationaal agentschap",
	CONTRACTING_AUTHORITY_TYPE_OTHER: "Andere",
	PUBLICATION_RADIUS_EUROPEAN_UNION: "Ja",
	PUBLICATION_RADIUS_NATIONAL: "Nee",
	HAS_ADDENDA: "Ja",
	DOES_NOT_HAVE_ADDENDA: "Nee",
	PROCEDURE_TYPE_OPEN: "Open",
	PROCEDURE_TYPE_RESTRICTED: "Beperkt",
	PROCEDURE_TYPE_COMPETITIVE_DIALOGUE: "Concurrentiegerichte dialoog",
	PROCEDURE_TYPE_INNOVATION_PARTNERSHIP: "Innovatiepartnerschap",
	PROCEDURE_TYPE_NEGOTIATED: "Onderhandeling",
	PROCEDURE_TYPE_OTHER: "Andere",
	IS_FRAMEWORK_AGREEMENT_YES: "Ja",
	IS_FRAMEWORK_AGREEMENT_NO: "Nee",
	IS_SHELTERED_WORKSHOP: "Enkel maatwerkbedrijven",
	IS_SHELTERED_PROGRAM: "Programma’s voor beschermde arbeid",
};
