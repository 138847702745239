import { ResourceKey } from "i18next";

export const CompanyPage: ResourceKey = {

    company_name: "Organisatie",
    company_information_section_title: "Bedrijfsinformatie",
    main_competitors_section_title: "Belangrijkste concurrenten",
    recent_activity_section_title: "Recente overheidsopdrachten",
    recently_won_section_title: "Recent gewonnen",
    recently_participated_in_section_title: "Recent deelgenomen",
    shared_projects_title: "Gedeelde projecten",
    main_buyers_section_title: "Belangrijkste kopers",
    tenders_contracted_open_title: "Openstaande tenders",
    tenders_contracted_closed_title: "Recent uitgeschreven tenders",
    main_suppliers_section_title: "Belangrijkste leveranciers",
    main_activities_section_title: "Soorten overheidsopdrachten",
    accreditations_title: "Erkenningen",
    bid: "offerte",
    award: "gunning",
    total_results: "Totaal aantal resultaten",
    disclaimer: "De bedrijfspagina is een beta-feature, en een eerste stap naar volwaardige marktanalyse. Geef feedback via info@tenderwolf.com",
    unknown_company: "Ongekend bedrijf",
    follow_company: "Volg dit bedrijf: hou me op de hoogte wanneer dit bedrijf deelneemt aan een overheidsopdracht en geef een overzicht in het gunningstabblad",
    show_company_page: "Toon bedrijfspagina",

};