import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Paginate } from "../../../components/paginate";
import {
  SearchResponse,
  TenderSearchInput,
  TenderSearchState,
  updateSearchFilters,
} from "../../../hooks/slices/tenderSearchSlice";
import {GenericSearchPageProps, SearchConfiguration} from "../GenericSearchPage";
import { SearchResultsBody, SearchResultsBodySkeleton } from "./body/SearchResultsBody";
import { SearchResultsHeader, SearchResultsHeaderSkeleton } from "./header/SearchResultsHeader";
import { SearchType } from "../../../consts/searchType";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { FeatureUtil } from "../../../utils/features";
import { FeatureName} from "../../../consts/features";
import { TFunction } from "i18next";
import { DateUtil } from "../../../utils/date";
import { Moment } from "moment";
import { SearchFilterUtil } from "../../../utils/searchFilters";
import {PlanDto} from "../../../hooks/slices/currentPlanSlice";
import {RaiseClient} from "../../../hooks/raiseClient";
import {Client} from "../../../types/client";
import {UserInteraction} from "../../../hooks/slices/userSlice";
import {RaiseUser} from "../../../hooks/raiseUser";
import {DateFilterUtil} from "../../../utils/dateFilters";

export const SearchResults: React.FC<GenericSearchPageProps> = (props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const lang = i18n.language; // something like en-US
  const languageIso = lang.substring(0, 2).toUpperCase(); // just becomes EN
  const tenderSearchOutput: SearchResponse | undefined = useAppSelector((state) => state.tenderSearch.searchResponse);
  let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    const tenderSearchInputWithFilters: TenderSearchInput | undefined = useAppSelector(
    (state) => state.tenderSearch.searchInput
  );
  const currentPlan: PlanDto | undefined = RaiseCurrentPlan().currentPlan;
  const client: Client | undefined = RaiseClient().client;
  //When a user has a limited amount of opportunities per update mail, they cannot use the filters in opportunities
  const tenderSearchInStore: TenderSearchState = useAppSelector((state) => state.tenderSearch);
  let opportunitiesLimit: number | undefined;
  if (tenderSearchInStore.searchType === SearchType.OPPORTUNITIES) {
    opportunitiesLimit = FeatureUtil.getFeatureLimit(currentPlan?.features, FeatureName.UPDATE_MAIL_OPPORTUNITIES);
  }
  const setPage = (page: number) => {
    dispatch(updateSearchFilters({ page: page, userInteractions: userInteractions}));
  };
  if (
    tenderSearchOutput === undefined ||
    tenderSearchOutput.tenders === undefined ||
    tenderSearchInputWithFilters === undefined ||
    props.searchConfiguration.sidebar.queryType === SearchType.DUMMY
  ) {
    return <SearchResultsSkeleton {...props.searchConfiguration} />;
  }
  if (tenderSearchInputWithFilters.metadata.sortBy === "AWARD_DATE_DESC") {
    props.searchConfiguration.tenderCard.showAwardDateInsteadOfDeadline = true;
  }
  let resultsHint: ResultsHint = getResultsHint(
    tenderSearchInputWithFilters,
    tenderSearchOutput,
    currentPlan,
    client,
    opportunitiesLimit,
    t
  );
  return (
    <>
      <div>
        <div className="search-filter-section-alignment">
          <SearchResultsHeader
            tenderSearchInput={tenderSearchInputWithFilters.mainInput}
            tenderSearchOutput={tenderSearchOutput}
            searchConfiguration={props.searchConfiguration}
            opportunitiesLimit={opportunitiesLimit}
            initialSearchFilterState={props.initialSearchFilterState}
          />
            {resultsHint.parts.length > 0 && (
                <ResultsHint {...resultsHint}/>
            )}
          <SearchResultsBody
            searchHits={tenderSearchOutput.tenders}
            languageIso={languageIso}
            cardConfiguration={props.searchConfiguration.tenderCard}
          />
          <Paginate
            currentPage={tenderSearchOutput.page}
            totalHits={tenderSearchOutput.totalHits}
            setPage={setPage}
            itemsPerPage={tenderSearchOutput.pageSize}
            maxPages={props.searchConfiguration.info.maxPages}
          />
        </div>
      </div>
    </>
  );
};

export function getResultsHint(query: TenderSearchInput,
                               results: SearchResponse,
                               currentPlan: PlanDto | undefined,
                               client: Client | undefined,
                               opportunitiesLimit: number | undefined,
                               t: TFunction): ResultsHint {
    if (currentPlan === undefined || client === undefined) return {parts: []};
    if(currentPlan.type === "TRIAL" && client.clientType !== "GOVERNMENT"){
        return {parts:[{text: t("searchFilterTags.trial_message_part_one")},
            {text: t("searchFilterTags.trial_message_part_two"), url: "/account?tab=feedback"},
            {text: t("searchFilterTags.trial_message_part_three")},
            {text: t("searchFilterTags.trial_message_part_four")},
            {text: t("searchFilterTags.trial_message_part_five"), url: "/account?tab=help"}
            ]}
    }
    if (opportunitiesLimit != null){
                return {parts:[{text: t("searchFilterTags.limited_results_message_part_one") +
        opportunitiesLimit +
        t("searchFilterTags.limited_results_message_part_two")}]}
    }
    let sideBarHasPublicationDateFilter = query.metadata.searchType !== SearchType.SHORTLIST;
    if (results.totalHits < 2 && sideBarHasPublicationDateFilter){
        let earliestStartDate: Moment | undefined = DateFilterUtil.getEarliestStartDateForUser(currentPlan.features);
        let earliestStartString = earliestStartDate ? earliestStartDate.format("DD-MM-YYYY") : "...";
        let start = query.mainInput.publicationStartDate;
        let end = query.mainInput.publicationEndDate;
        let startString = start ? DateUtil.getDateMoment(start).format("DD-MM-YYYY") : "...";
        let endString = end ? DateUtil.getDateMoment(end).format("DD-MM-YYYY") : "...";
        if (start != null && end != null && earliestStartString !== startString){
            return {parts:[{text: t("searchFilterTags.hints_date_message_part_one")
                + startString + t("searchFilterTags.hints_date_message_part_two") + endString +
                t("searchFilterTags.hints_date_message_part_three")}]};
        }
    } return {parts: []};
}

interface ResultsHint {
    parts: ResultsHintPart[];
}
interface ResultsHintPart {
    text: string;
    url?: string;
}

const ResultsHint: React.FC<ResultsHint> = ({parts}) => {
    return <div className={"limited-results-disclaimer"}>
        {parts.map((part, i) =>
            <span key={i}>
                {part.url ?
                    <span><a href={part.url} key={i}>{part.text}</a></span>
                    :
                    <span>{part.text}</span>
                }
            </span>

        )}
    </div>
}


export const SearchResultsSkeleton: React.FC<SearchConfiguration> = (props) => {
  return (
    <>
      <div>
        <div className="search-filter-section-alignment">
          <SearchResultsHeaderSkeleton {...props} />
          <SearchResultsBodySkeleton />
        </div>
      </div>
    </>
  );
};
