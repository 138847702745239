import { apiSlice } from "./apiSlice";

export interface LogInput {
  message: string;
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        log: build.mutation<void, LogInput>({
            query: (params: LogInput) => {
                return {
                    url: `/shaggy/log`,
                    method: 'POST',
                    body: params,
                };
            },
        })
    }),
});

export const {useLogMutation} = extendedApiSlice;
