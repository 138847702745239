import React from "react";

import AccountSvg from "../../assets/icons/account.svg";
import AccountBlueSvg from "../../assets/icons/account-blue.svg";
import CloseSvg from "../../assets/icons/close.svg";
import MobileMenuSvg from "../../assets/icons/mobile-hamburger.svg";
import StarBlueSvg from "../../assets/icons/star-blue.svg";
import StarSvg from "../../assets/icons/star.svg";
import StarFillSvg from "../../assets/icons/star-fill.svg";
import SearchSvg from "../../assets/icons/search.svg";
import SearchBlueSvg from "../../assets/icons/search-blue.svg";
import GearSvg from "../../assets/icons/gear.svg";
import TrashcanSvg from "../../assets/icons/trashcan.svg";
import TrashcanBlueSvg from "../../assets/icons/trashcan-blue.svg";
import TrashcanBlueFillSvg from "../../assets/icons/trashcan-blue-fill.svg";
import MinusSvg from "../../assets/icons/minus.svg";
import PlusSvg from "../../assets/icons/plus.svg";
import PlusAltSvg from "../../assets/icons/plus-alt.svg";
import BesixForceSvg from "../../assets/icons/besixforce.svg";
import ResetSvg from "../../assets/icons/reset.svg";
import CheckmarkSvg from "../../assets/icons/check.svg";
import CheckmarkWhiteSvg from "../../assets/icons/check-white.svg";
import NotificationBellSvg from "../../assets/icons/notification.svg";
import PlusCircledSvg from "../../assets/icons/plus-circled.svg";
import PublicationDateSvg from "../../assets/icons/publication-date.svg";
import PublicationDateBlueSvg from "../../assets/icons/publication-date-blue.svg";
import DeadlineSvg from "../../assets/icons/deadline.svg";
import DeadlineBlueSvg from "../../assets/icons/deadline-blue.svg";
import EuroCircledSvg from "../../assets/icons/euro-circled.svg";
import EuroCircledBlueSvg from "../../assets/icons/euro-circled-blue.svg";
import StarBlueFillSvg from "../../assets/icons/star-blue-fill.svg";
import LabelSvg from "../../assets/icons/label.svg";
import LabelBlueSvg from "../../assets/icons/label-blue.svg";
import ShareSvg from "../../assets/icons/share.svg";
import ShareBlueSvg from "../../assets/icons/share-blue.svg";
import TranslateSvg from "../../assets/icons/translate.svg";
import TranslateBlueSvg from "../../assets/icons/translate-blue.svg";
import DownloadSvg from "../../assets/icons/download.svg";
import DownloadBlueSvg from "../../assets/icons/download-blue.svg";
import ExportSvg from "../../assets/icons/export.svg";
import ExportXLSSvg from "../../assets/icons/export-xls.svg";
import LinkedInSvg from "../../assets/icons/social-linkedin.svg";
import LocationSvg from "../../assets/icons/location.svg";
import LocationWhiteSvg from "../../assets/icons/location-white.svg";
import LocationBlueSvg from "../../assets/icons/location-blue.svg";
import EmailSvg from "../../assets/icons/email.svg";
import EmailWhiteSvg from "../../assets/icons/email-white.svg";
import PhoneSvg from "../../assets/icons/phone.svg";
import PhoneWhiteSvg from "../../assets/icons/phone-white.svg";
import SubjectCodeSvg from "../../assets/icons/subject-code.svg";
import SubjectCodeBlueSvg from "../../assets/icons/subject-code-blue.svg";
import ArrowDownSvg from "../../assets/icons/arrow-down.svg";
import ArrowUpSvg from "../../assets/icons/arrow-up.svg";
import ArrowLeftSvg from "../../assets/icons/arrow-left.svg";
import ArrowRightSvg from "../../assets/icons/arrow-right.svg";
import ArrowLeftDoubleSvg from "../../assets/icons/arrow-left-double.svg";
import ArrowRightDoubleSvg from "../../assets/icons/arrow-right-double.svg";
import ArrowRightAltSvg from "../../assets/icons/arrow-right-alt.svg";
import SettingsSvg from "../../assets/icons/settings.svg";
import CraneBlueSvg from "../../assets/icons/crane-blue.svg";
import DotsWhiteSvg from "../../assets/icons/dots-white.svg";
import DotsBlackSvg from "../../assets/icons/dots-black.svg";
import SparklesSvg1 from "../../assets/icons/sparkles-1.svg";
import SparklesSvg1Blue from "../../assets/icons/sparkles-1-blue.svg";
import SparklesSvg2 from "../../assets/icons/sparkles-2.svg";
import SparklesSvg3 from "../../assets/icons/sparkles-3.svg";
import SparklesSvg4 from "../../assets/icons/sparkles-4.svg";
import SparklesSvg5 from "../../assets/icons/sparkles-5.svg";
import CompanySvg1 from "../../assets/icons/company_1.svg";
import CompanySvg2 from "../../assets/icons/company_2.svg";
import CompanySvg3 from "../../assets/icons/company_3.svg";
import CompanySvg21 from "../../assets/icons/company_21.svg";
import CompanySvg23 from "../../assets/icons/company_23.svg";
import CompanySvg23Blue from "../../assets/icons/company_23_blue.svg";
import CompanySvg5 from "../../assets/icons/company_5.svg";
import CompanySvg5Blue from "../../assets/icons/company_23_blue.svg";
import NavigateSvg from "../../assets/icons/navigate.svg";
import GovernmentIconSvg from "../../assets/icons/company_27.svg";
import SchoolIconSvg from "../../assets/icons/company_21.svg";
import HospitalIconSvg from "../../assets/icons/hospital.svg";
import ArchitectureIconSvg from "../../assets/icons/architecture.svg";
import ClothingIconSvg from "../../assets/icons/clothing.svg";
import CreditCardIconSvg from "../../assets/icons/credit_card.svg";
import EconomyIconSvg from "../../assets/icons/economy.svg";
import FurnitureIconSvg from "../../assets/icons/furniture.svg";
import GardeningIconSvg from "../../assets/icons/gardening.svg";
import GuardIconSvg from "../../assets/icons/guard.svg";
import RecyclingIconSvg from "../../assets/icons/recycling.svg";
import SoftwareIconSvg from "../../assets/icons/software.svg";
import SyringeIconSvg from "../../assets/icons/syringe.svg";
import TransportationIconSvg from "../../assets/icons/transportation.svg";
import VehicleIconSvg from "../../assets/icons/vehicle.svg";
import WrenchIconSvg from "../../assets/icons/wrench.svg";
import ConstructionIconSvg from "../../assets/icons/construction.svg";
import MatchSvg from "../../assets/icons/match.svg";
import MatchBlueSvg from "../../assets/icons/match-blue.svg";
import PinSvg from "../../assets/icons/pin2.svg";
import SparklesSvgOrange from "../../assets/icons/sparkles-orange.svg";

export const PinIcon: React.FC = () => {
  return <img src={PinSvg} alt={"Pin"}></img>;
}

export const CloseIcon: React.FC = () => {
  return <img src={CloseSvg} alt={"Close"}></img>;
};
export const CraneBlueIcon: React.FC = () => {
  return <img src={CraneBlueSvg} alt={"Crane"}></img>;
};
export const MobileMenuIcon: React.FC = () => {
  return <img src={MobileMenuSvg} alt="Menu" />;
};
export const StarIcon: React.FC = () => {
  return <img src={StarSvg} alt="Follow" />;
};
export const StarFillIcon: React.FC = () => {
  return <img src={StarFillSvg} alt="Follow" />;
};
export const StarBlueIcon: React.FC = () => {
  return <img src={StarBlueSvg} alt="Follow" />;
};
export const StarBlueFillIcon: React.FC = () => {
  return <img src={StarBlueFillSvg} alt="Follow" />;
};
export const SearchIcon: React.FC = () => {
  return <img src={SearchSvg} alt="Search" />;
};
export const SearchBlueIcon: React.FC = () => {
  return <img src={SearchBlueSvg} alt="Search" />;
};
export const GearIcon: React.FC = () => {
  return <img src={GearSvg} alt="Settings" />;
};
export const TrashIcon: React.FC = () => {
  return <img src={TrashcanSvg} alt="Remove" />;
};
export const TrashBlueIcon: React.FC = () => {
  return <img src={TrashcanBlueSvg} alt="Remove" />;
};
export const TrashBlueFillIcon: React.FC = () => {
  return <img src={TrashcanBlueFillSvg} alt="Remove" />;
};
export const MinusIcon: React.FC = () => {
  return <img src={MinusSvg} alt="Remove" />;
};
export const PlusIcon: React.FC = () => {
  return <img src={PlusSvg} alt="Add" />;
};
export const PlusAltIcon: React.FC = () => {
  return <img src={PlusAltSvg} alt="Add" />;
};
export const BesixForceIcon: React.FC = () => {
  return <img src={BesixForceSvg} alt="BesixForce" />;
};
export const ResetIcon: React.FC = () => {
  return <img src={ResetSvg} alt="Reset" />;
};
export const CheckIcon: React.FC = () => {
  return <img src={CheckmarkSvg} alt="Checkmark" />;
};
export const CheckWhiteIcon: React.FC = () => {
  return <img src={CheckmarkWhiteSvg} alt="Checkmark" />;
};
export const NotificationBellIcon: React.FC = () => {
  return <img src={NotificationBellSvg} alt="Notification bell" />;
};
export const PlusCircledIcon: React.FC = () => {
  return <img src={PlusCircledSvg} alt="Add" />;
};
export const PublicationDateIcon: React.FC = () => {
  return <img src={PublicationDateSvg} alt="Publication date" />;
};
export const PublicationDateBlueIcon: React.FC = () => {
  return <img src={PublicationDateBlueSvg} alt="Publication date" />;
};
export const DeadlineIcon: React.FC = () => {
  return <img src={DeadlineSvg} alt="Deadline" />;
};
export const DeadlineBlueIcon: React.FC = () => {
  return <img src={DeadlineBlueSvg} alt="Deadline" />;
};
export const EuroCircledIcon: React.FC = () => {
  return <img src={EuroCircledSvg} alt="Amount" />;
};
export const EuroCircledBlueIcon: React.FC = () => {
  return <img src={EuroCircledBlueSvg} alt="Amount" />;
};
export const RegionCodeIcon: React.FC = () => {
  return <img src={LocationSvg} alt="Region code" />;
};
export const RegionCodeBlueIcon: React.FC = () => {
  return <img src={LocationBlueSvg} alt="Region code" />;
};
export const SubjectCodeIcon: React.FC = () => {
  return <img src={SubjectCodeSvg} alt="Subject code" />;
};
export const SubjectCodeBlueIcon: React.FC = () => {
  return <img src={SubjectCodeBlueSvg} alt="Subject code" />;
};
export const RemoveIcon: React.FC = () => {
  return <img src={TrashcanSvg} alt="Remove" />;
};
export const UnremoveIcon: React.FC = () => {
  return <img src={TrashcanSvg} alt="Remove" />;
};
export const FollowIcon: React.FC = () => {
  return <img src={LocationSvg} alt="Follow" />;
};
export const SimulateMatchIcon: React.FC = () => {
  return <img src={LabelBlueSvg} alt="Simulate match" />;
};
export const LabelIcon: React.FC = () => {
  return <img src={LabelSvg} alt="Label" />;
};
export const LabelBlueIcon: React.FC = () => {
  return <img src={LabelBlueSvg} alt="Label" />;
};
export const ShareIcon: React.FC = () => {
  return <img src={ShareSvg} alt="Share" />;
};
export const ShareBlueIcon: React.FC = () => {
  return <img src={ShareBlueSvg} alt="Share" />;
};
export const TranslateIcon: React.FC = () => {
  return <img src={TranslateSvg} alt="Translate" />;
};
export const TranslateBlueIcon: React.FC = () => {
  return <img src={TranslateBlueSvg} alt="Translate" />;
};
export const DownloadIcon: React.FC = () => {
  return <img src={DownloadSvg} alt="Download" />;
};
export const DownloadBlueIcon: React.FC = () => {
  return <img src={DownloadBlueSvg} alt="Download" />;
};
export const ExportIcon: React.FC = () => {
  return <img src={ExportSvg} alt="Export" />;
};
export const ExportXLSIcon: React.FC = () => {
  return <img src={ExportXLSSvg} alt="Export" />;
};
export const LinkedInIcon: React.FC = () => {
  return <img src={LinkedInSvg} alt="LinkedIn" />;
};
export const LocationIcon: React.FC = () => {
  return <img src={LocationSvg} alt="Location" />;
};
export const LocationWhiteIcon: React.FC = () => {
  return <img src={LocationWhiteSvg} alt="Location" />;
};
export const PhoneIcon: React.FC = () => {
  return <img src={PhoneSvg} alt="Phone" />;
};
export const PhoneWhiteIcon: React.FC = () => {
  return <img src={PhoneWhiteSvg} alt="Phone" />;
};
export const EmailIcon: React.FC = () => {
  return <img src={EmailSvg} alt="Email" />;
};
export const EmailWhiteIcon: React.FC = () => {
  return <img src={EmailWhiteSvg} alt="Email" />;
};
export const AccountIcon: React.FC = () => {
  return <img src={AccountSvg} alt="Account settings" />;
};
export const AccountBlueIcon: React.FC = () => {
  return <img src={AccountBlueSvg} alt="Account settings" />;
};
export const ArrowDownIcon: React.FC = () => {
  return <img src={ArrowDownSvg} alt="Down" />;
};
export const ArrowUpIcon: React.FC = () => {
  return <img src={ArrowUpSvg} alt="Up" />;
};
export const ArrowLeftIcon: React.FC = () => {
  return <img src={ArrowLeftSvg} alt="Left" />;
};
export const ArrowRightIcon: React.FC = () => {
  return <img src={ArrowRightSvg} alt="Right" />;
};
export const ArrowLeftDoubleIcon: React.FC = () => {
  return <img src={ArrowLeftDoubleSvg} alt="Left" />;
};
export const ArrowRightDoubleIcon: React.FC = () => {
  return <img src={ArrowRightDoubleSvg} alt="Right" />;
};
export const ArrowRightAltIcon: React.FC = () => {
  return <img src={ArrowRightAltSvg} alt="Right" />;
};
export const SettingsIcon: React.FC = () => {
  return <img src={SettingsSvg} alt="Settings" />;
};
export const DotsWhiteIcon: React.FC = () => {
  return <img src={DotsWhiteSvg} alt="Dots" />;
};
export const DotsBlackIcon: React.FC = () => {
  return <img src={DotsBlackSvg} alt="Dots" />;
};
export const SparklesIcon: React.FC = () => {
  return <img src={SparklesSvg1} alt="Sparkles" />;
};
export const SparklesBlueIcon: React.FC = () => {
  return <img src={SparklesSvg1Blue} alt="Sparkles" />;
};
export const SparklesOrangeIcon: React.FC = () => {
  return <img src={SparklesSvgOrange} alt="Sparkles" />;
};
export const SparklesIcon2: React.FC = () => {
  return <img src={SparklesSvg2} alt="Sparkles" />;
};
export const SparklesIcon3: React.FC = () => {
  return <img src={SparklesSvg3} alt="Sparkles" />;
};
export const SparklesIcon4: React.FC = () => {
  return <img src={SparklesSvg4} alt="Sparkles" />;
};
export const SparklesIcon5: React.FC = () => {
  return <img src={SparklesSvg5} alt="Sparkles" />;
};
export const CompanyIcon1: React.FC = () => {
  return <img src={CompanySvg1} alt="Company" />;
}
export const CompanyIcon5: React.FC = () => {
  return <img src={CompanySvg5} alt="Company" />;
}

export const CompanyIcon2: React.FC = () => {
  return <img src={CompanySvg2} alt="Company" />;
}
export const CompanyIcon3: React.FC = () => {
  return <img src={CompanySvg3} alt="Company" />;
}
export const NavigateIcon: React.FC = () => {
    return <img src={NavigateSvg} alt="Navigate" />;
}
export const GovernmentIcon: React.FC = () => {
    return <img src={GovernmentIconSvg} alt="Government" />;
}
export const SchoolIcon: React.FC = () => {
  return <img src={SchoolIconSvg} alt="School"/>;
}
export const CompanyIcon21: React.FC = () => {
  return <img src={CompanySvg21} alt="Company" />;
}
export const HospitalIcon: React.FC = () => {
  return <img src={HospitalIconSvg} alt="Hospital" />;
}

export const CompanyIcon23: React.FC = () => {
    return <img src={CompanySvg23} alt="Company" />;
}

export const CompanyIcon23Blue: React.FC = () => {
  return <img src={CompanySvg23Blue} alt="Company" />;
}

export const ArchitectureIcon: React.FC = () => {return <img src={ArchitectureIconSvg} alt = "Architecture"/>}
export const ClothingIcon: React.FC = () => {return <img src={ClothingIconSvg} alt = "Clothing"/>}
export const CreditCardIcon: React.FC = () => {return <img src={CreditCardIconSvg} alt = "CreditCard"/>}
export const EconomyIcon: React.FC = () => {return <img src={EconomyIconSvg} alt = "Economy"/>}
export const FurnitureIcon: React.FC = () => {return <img src={FurnitureIconSvg} alt = "Furniture"/>}
export const GardeningIcon: React.FC = () => {return <img src={GardeningIconSvg} alt = "Gardening"/>}
export const GuardIcon: React.FC = () => {return <img src={GuardIconSvg} alt = "Guard"/>}
export const RecyclingIcon: React.FC = () => {return <img src={RecyclingIconSvg} alt = "Recycling"/>}
export const SoftwareIcon: React.FC = () => {return <img src={SoftwareIconSvg} alt = "Software"/>}
export const SyringeIcon: React.FC = () => {return <img src={SyringeIconSvg} alt = "Syringe"/>}
export const TransportationIcon: React.FC = () => {return <img src={TransportationIconSvg} alt = "Transportation"/>}
export const VehicleIcon: React.FC = () => {return <img src={VehicleIconSvg} alt = "Vehicle"/>}
export const WrenchIcon: React.FC = () => {return <img src={WrenchIconSvg} alt = "Wrench"/>}
export const ConstructionIcon: React.FC = () => {return <img src={ConstructionIconSvg} alt = "Construction"/>}
export const MatchIcon: React.FC = () => {return <img src={MatchSvg} alt = "Match"/>}
export const MatchBlueIcon: React.FC = () => {return <img src={MatchBlueSvg} alt = "Match"/>}