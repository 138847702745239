import {ResourceKey} from "i18next";

export const DateFilters: ResourceKey ={

	PUBLICATION_DATE_EVERYTHING: "Everything",
	PUBLICATION_DATE_YESTERDAY: "Most recent",
	PUBLICATION_DATE_LAST_WEEK: "Last week",
	PUBLICATION_DATE_LAST_MONTH: "Last month",
	PUBLICATION_DATE_LAST_TWO_MONTHS: "Last two months",
	PUBLICATION_DATE_LAST_YEAR: "Last year",
	PUBLICATION_DATE_CUSTOM: "Custom",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING: "Everything",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY: "Most recent",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK: "Last week",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH: "Last month",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR: "Last year",
	AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM: "Custom",
	ESTIMATED_RENEWAL_DATE_NEXT_MONTH: "Next month",
	ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS: "Three months",
	ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS: "Six months",
	ESTIMATED_RENEWAL_DATE_NEXT_YEAR: "Next year",
	ESTIMATED_RENEWAL_DATE_ALL: "All",
	ESTIMATED_RENEWAL_DATE_CUSTOM: "Custom",
	DEADLINE_EVERYTHING: "Everything",
	DEADLINE_NOT_YET_EXPIRED: "Not yet expired",
	DEADLINE_IN_7_DAYS: "7 days",
	DEADLINE_IN_14_DAYS: "14 days",
	DEADLINE_IN_1_MONTH: "1 month",
	DEADLINE_IN_2_MONTHS: "2 months",
	DEADLINE_CUSTOM: "Custom",

};
