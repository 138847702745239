import React from "react";
import {DateUtil} from "../../../../utils/date";
import {Moment} from "moment/moment";
import {ArrowRightAltIcon, CloseIcon} from "../../../../components/icons";
import {useTranslation} from "react-i18next";

interface DateRangePickerProps {
    defaultStart?: Moment | undefined;
    minStart?: Moment | undefined;
    defaultEnd?: Moment | undefined;
    commit: (start: Moment, end: Moment) => void;
    close: () => void;
}

export const CustomDateRangePicker: React.FC<DateRangePickerProps> = (props) => {
    const {t} = useTranslation();
    let min: Moment = props.minStart ? props.minStart : DateUtil.getDateMoment("2012-11-08");
    const [customStartDate, setCustomStartDate] = React.useState<Moment>(props.defaultStart ? props.defaultStart : DateUtil.todayMinusYears(1));
    const [customEndDate, setCustomEndDate] = React.useState<Moment>(props.defaultEnd ? props.defaultEnd : DateUtil.today());
    const choose = () => {props.commit(customStartDate, customEndDate);}
    const submitOnEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {if (e.key === "Enter"){choose();}}
    return (
      <>
        <div className="popup-container" onKeyDown={submitOnEnter}>
          <div className="popup-modal" style={{width: "fit-content"}}>
            <div className="popup-header-alignment">
              <p>{t("publications.chooseADate")}</p>
              <div onClick={props.close}>
                <CloseIcon />
              </div>
            </div>
            <div className="homebrew-date-picker-container">
              <CustomDateRangePickerDateInput min={min} def={customStartDate} max={DateUtil.todayPlusYears(100)} change={setCustomStartDate} focus={true}/>
                <div className={"homebrew-date-picker-arrow"}>
                    <ArrowRightAltIcon/>
                </div>
              <CustomDateRangePickerDateInput min={min} def={customEndDate} max={DateUtil.todayPlusYears(100)} change={setCustomEndDate} focus={false}/>
            </div>
            <div className="popup-confirm-button">
              <button onClick={choose}>{t("publications.filter")}</button>
          </div>
        </div>
        </div>
      </>
    );
}

const CustomDateRangePickerDateInput: React.FC<{min: Moment, def: Moment, max: Moment, change: (m: Moment) => void, focus: boolean}> = ({min, def, max, change, focus}) => {

    const changeDay = (n: number) => {
        let m = def.clone();
        m.date(n);
        change(m);
    }
    const changeMonth = (n: number) => {
        let m = def.clone();
        m.month(n-1);
        change(m);
    }
    const changeYear = (n: number) => {
        let m = def.clone();
        m.year(n);
        change(m);
    }
    return <>
        <div className={"homebrew-date-picker"}>
                <CustomDateRangePickerNumber min={1} max={31} def={def.date()} change={changeDay} focus={focus}/>
                <CustomDateRangePickerNumber min={1} max={12} def={def.month()+1} change={changeMonth} focus={false}/>
                <CustomDateRangePickerNumber min={min.year()} max={max.year()} def={def.year()} change={changeYear} focus={false}/>
        </div>
    </>
}

const CustomDateRangePickerNumber: React.FC<{min: number, def: number, max: number, change: (n: number) => void, focus: boolean}> = ({min, max, def, change, focus}) => {
    const handleFocus = (event: { target: { select: () => any } }) => event.target.select();
    const enforceBounds = (e: React.ChangeEvent<HTMLInputElement>) => {
        let n = e.currentTarget.valueAsNumber;
        if (n >= min && n <= max){
            change(n);
        }
    }
    return <>
        <div className={"homebrew-date-picker-input-wrapper"}>
            <div className={"homebrew-date-picker-input"} >
                {focus && <input type="number" min={min} max={max} defaultValue={def} onChange={enforceBounds} autoFocus onFocus={handleFocus}/>}
                {!focus && <input type="number" min={min} max={max} defaultValue={def} onChange={enforceBounds}/>}
            </div>
        </div>
    </>

}