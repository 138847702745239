import React, { useEffect, useState } from "react";
import "./regionCodes.scss";
import { useTranslation } from "react-i18next";
import { CodeBranch, CodeType } from "../../../types/code";
import { MinusIcon, PlusIcon } from "../../../components/icons";
import { Feature } from "../../../consts/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import moment from "moment";
import { DebounceTextInput } from "../../../components/debounceInput/DebounceTextInput";
import { CodeTreeUtil, CodeUtil } from "../../../utils/codes";
import { FeatureUtil } from "../../../utils/features";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { SavedSearchDetailsTabProps } from "../index";

export const RegionCodePage: React.FC<SavedSearchDetailsTabProps> = ({
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  userUuid,
  isLoading,
}) => {
  const clientFeatures: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  if (clientFeatures === undefined) return <></>;
  else {
    let relevantRegionCodes: string[] = FeatureUtil.getRelevantRegionCodesFromFeatures(clientFeatures);
    return (
      <RegionCodePageWithData
        savedSearch={savedSearch}
        updateSavedSearch={updateSavedSearch}
        userUuid={userUuid}
        isLoading={isLoading}
        relevantRegionCodes={relevantRegionCodes}
        setUnsavedChanges={setUnsavedChanges}
      />
    );
  }
};

export interface RegionCodePageWithDataProps extends SavedSearchDetailsTabProps{
    relevantRegionCodes: string[];
}

export const RegionCodePageWithData: React.FC<RegionCodePageWithDataProps> = ({savedSearch, updateSavedSearch, relevantRegionCodes, setUnsavedChanges, userUuid, isLoading}) => {

    let {t} = useTranslation();
    const [selectedCodes, setSelectedCodes] = useState<CodeBranch[]>(savedSearch.regionCodes !== undefined ? savedSearch.regionCodes : []);
    const [disableSaveButton, setDisableSaveButton] = useState(true);
    const updateRegionCodes = (): boolean => {
        if(savedSearch.uuid) {
            updateSavedSearch({name: savedSearch.name, userUuid: savedSearch.userUuid, uuid: savedSearch.uuid, regionCodes: selectedCodes})
            setUnsavedChanges(false);
            setDisableSaveButton(true);
            return true;
        } else return false;
    }
    const selectCodes = (codes: CodeBranch[]) => {
        setSelectedCodes(codes);
        setUnsavedChanges(true);
        setDisableSaveButton(false);
    }
    const [regionCodesToShow, setRegionCodesToShow] = useState(relevantRegionCodes);
    const [showAll, setShowAll] = useState(false);
    const searchRegionCodes = (query: string) => {
        if(query === "") resetRegionCodes();
        else {
            let codesFound: string[] = CodeUtil.searchCodesAndReturnWithAncestors(query, relevantRegionCodes , CodeType.NUTS,10000, t);
            setRegionCodesToShow(codesFound);
        }
    };
    const resetRegionCodes = () => {
        setShowAll(false);
        setRegionCodesToShow(relevantRegionCodes);
    };

    useEffect(() => {
        if (regionCodesToShow.length < relevantRegionCodes.length){
            setShowAll(true); //problem: showall re-renders all 9000+ Cpv components
        }
    }, [regionCodesToShow])

    return (
        <>
            <div>
                <div className="region-all-content-alignment">
                    <div className="radio-region-alignment">
                        <DebounceTextInput search={searchRegionCodes} delay={300} placeHolder={t("settings.search")}/>
                        <RegionCodeTree
                            regionCodesToShow={regionCodesToShow}
                            setSelectedCodes={selectCodes}
                            selectedCodes={selectedCodes}
                            showAll={showAll}
                            setUnsavedChanges={setUnsavedChanges}/>
                    </div>
                </div>
            </div>
            <div className={'fill-button'}>
                <SaveButton saving={isLoading} save={updateRegionCodes} disabled={disableSaveButton}/>
            </div>
        </>
    );
}
interface RegionCodeTreeProps{
    regionCodesToShow: string[],
    selectedCodes: CodeBranch[],
    setSelectedCodes: (codes: CodeBranch[]) => void;
    setUnsavedChanges: (unsavedChanges: boolean) => void;
    showAll: boolean,
}
export const RegionCodeTree: React.FC<RegionCodeTreeProps> = ({regionCodesToShow, selectedCodes, setSelectedCodes, showAll, setUnsavedChanges}) => {
    const rootRegionCodes: string[] = regionCodesToShow.filter(c => c.length === 2);
    const isSelected = (cpv: string): boolean => {return CodeTreeUtil.isSelected(cpv, selectedCodes, CodeType.NUTS);}
    const addCodeWithAncestors = (cpv: string) => {
        setSelectedCodes(CodeTreeUtil.addCodeWithDescendants(cpv, selectedCodes, CodeType.NUTS));
    }
    const removeCodeWithAncestors = (cpv: string) => {
        setSelectedCodes(CodeTreeUtil.removeCodeWithDescendants(cpv, selectedCodes, regionCodesToShow, CodeType.NUTS));
    }
    const toggleCode = (cpv: string): void => {
        if(isSelected(cpv)) removeCodeWithAncestors(cpv);
        else addCodeWithAncestors(cpv);
        setUnsavedChanges(true);
    }
    const hasSelectedDescendant = (cpv: string): boolean => {
        return CodeTreeUtil.hasSelectedDescendant(cpv, selectedCodes, CodeType.NUTS);
    }
    const isInclusive = (cpv: string): boolean => {
        return CodeTreeUtil.isInclusive(cpv, selectedCodes, CodeType.NUTS);
    }

    return (
        <div className="all-content-region-list-alignment">
            {rootRegionCodes.length === 0 && <div className='no-results-text'>No results</div>}
            {rootRegionCodes.map((nuts, i) =>
                <RegionCodeBranch
                    key={codeKey(nuts, showAll)}
                    code={nuts}
                    toggleCode={toggleCode}
                    isSelected={isSelected}
                    hasSelectedDescendant={hasSelectedDescendant}
                    hasOnlySelectedDescendants={isInclusive}
                    regionCodesToShow={regionCodesToShow}
                    showAllDescendants={showAll}/>)}
        </div>
    );
}

function codeKey(code: string, show: boolean){
    return code + "-" + show + "-" + moment().valueOf();
}

interface NutsProps{
    code: string;
    toggleCode: (nuts: string) => void;
    isSelected: (nuts: string) => boolean;
    hasSelectedDescendant: (nuts: string) => boolean;
    hasOnlySelectedDescendants: (nuts: string) => boolean;
    regionCodesToShow: string[];
    showAllDescendants: boolean;
}

const RegionCodeBranch: React.FC<NutsProps> = ({code, toggleCode, isSelected, hasSelectedDescendant, hasOnlySelectedDescendants, regionCodesToShow, showAllDescendants}) => {
    const {t} = useTranslation();
    const isChecked = isSelected(code);
    const hasChildren = CodeUtil.hasChildren(code, regionCodesToShow, CodeType.NUTS);
    const [isOpen, setIsOpen] = useState(showAllDescendants || (!isChecked && hasSelectedDescendant(code)) || (isChecked && !hasOnlySelectedDescendants(code)));

    // const [isOpen, setIsOpen] = useState(showAllDescendants || (!isChecked && hasSelectedDescendant(code)));
    const [children, setChildren] = useState<string[]>(isOpen ? CodeUtil.findChildrenFromBranch(code, regionCodesToShow, CodeType.NUTS) : [])
    const showChildren = () => {setChildren(CodeUtil.findChildrenFromBranch(code, regionCodesToShow, CodeType.NUTS)); setIsOpen(true);}
    const hideChildren = () => {setChildren([]); setIsOpen(false);}
    const toggleChildrenVisbility = () => {
        if(!isOpen) showChildren();
        else hideChildren();

    }
    const toggleCodeAndChildren =() => {
        toggleCode(code);
        hideChildren();
    }
    let level = CodeUtil.getLevel(code, CodeType.NUTS);

    let className = level === 0 ? "first-layout-alignment" : "sec-content-alignment sec-content-alignment-left-" + (level)
    let codeId = codeKey(code, showAllDescendants); //this id needs to be distinct from other profiles' code keys otherwise those can get toggled instead
    return <>
        <div>
            <div className={className}>
                {hasChildren && isOpen && <div className={'center-align'} onClick={toggleChildrenVisbility} data-cy={"hide-children-button"}><MinusIcon/></div>}
                {hasChildren && !isOpen && <div className={'center-align'} onClick={toggleChildrenVisbility} data-cy={"show-children-button"}><PlusIcon/></div>}
                {!hasChildren && <div className={'center-align'}><MinusIcon/></div>}
                <div>
                    <div className='child-product'>
                        <input onClick={toggleCodeAndChildren} defaultChecked={isChecked} type="checkbox" id={codeId} data-cy={"input-checkbox"}/>
                        <label htmlFor={codeId} ><span>{CodeUtil.getCodeWithTranslation({code: code, type: CodeType.NUTS}, t)}</span></label>
                    </div>
                </div>
            </div>
            {children.map((nuts) =>
                <RegionCodeBranch key={codeKey(nuts, showAllDescendants)}
                      code={nuts}
                      isSelected={isSelected}
                      toggleCode={toggleCode}
                      hasSelectedDescendant={hasSelectedDescendant}
                      hasOnlySelectedDescendants={hasOnlySelectedDescendants}
                      regionCodesToShow={regionCodesToShow}
                      showAllDescendants={showAllDescendants}/>)}
        </div>

    </>
}