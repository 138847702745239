import { Country, CountryParser } from "../consts/countries";
import { Feature } from "../consts/features";
import { SearchFilter, SearchFilterParser } from "../consts/SearchFilter";
import { FeatureUtil } from "./features";
import {DateFilter, SearchFilterState} from "../hooks/slices/tenderSearchSlice";
import { FieldGroup } from "../consts/FieldGroup";

export namespace SearchFilterUtil {
  export function contains(filters: SearchFilter[] | undefined, filter: SearchFilter) {
    if (filters) return filters.includes(filter);
    else return false;
  }

  export function isCountryFilter(filter: SearchFilter) {
    return filter.startsWith("COUNTRY_");
  }

  export function getCountriesFromCountryFilters(searchFilters: SearchFilter[]): Country[] {
    return searchFilters
      .filter((f) => isCountryFilter(f))
      .map((f) => f.replace("COUNTRY_", ""))
      .map((f) => CountryParser.fromString(f));
  }

  export function getCountryFilters(): SearchFilter[] {
    return SearchFilterParser.findAll().filter((f) => SearchFilterUtil.isCountryFilter(f));
  }

  export function getCountryFiltersFromAvailableCountries(availableCountries: Country[]): SearchFilter[] {
    let filters: SearchFilter[] = [];
    for (const f of getCountryFilters()) {
      for (const c of availableCountries) {
        if (f.replace("COUNTRY_", "") === c) filters.push(f);
      }
    }
    return filters;
  }

  export function getCountryFiltersFromFeatures(features: Feature[] | undefined) {
    if (features) {
      let availableCountries = FeatureUtil.getCountriesFromPublisherFeatures(features);
      return getCountryFiltersFromAvailableCountries(availableCountries);
    } else return [];
  }

  export function addOrRemoveSearchFilter(
    element: SearchFilter,
    currentlySelectedFilters: SearchFilter[]
  ): SearchFilter[] {
    if (currentlySelectedFilters.map((e) => e).includes(element))
      return currentlySelectedFilters.filter((e) => e !== element);
    else return [...currentlySelectedFilters, element];
  }

  export function changeDropdownDateFilter(
    newFilter: DateFilter,
    dropdownFilters: DateFilter[],
    currentlySelectedFilters: DateFilter[]
  ): DateFilter[] {
    let currentlySelectedFiltersWithoutDropdownValues = currentlySelectedFilters.filter(
      (f) => !dropdownFilters.includes(f)
    );
    return [...currentlySelectedFiltersWithoutDropdownValues, newFilter];
  }

  export function getPublicationTypeFiltersForSavedSearch(): SearchFilter[] {
    return [
      SearchFilter.HAS_PRIOR_INFORMATION_NOTICE,
      SearchFilter.HAS_CONTRACT_NOTICE,
      SearchFilter.HAS_DESIGN_CONTEST,
      SearchFilter.HAS_MUNICIPAL_DECISION,
    ];
  }

  export function getPublicationTypeFilters(): SearchFilter[] {
    return [
      SearchFilter.HAS_PRIOR_INFORMATION_NOTICE,
      SearchFilter.HAS_CONTRACT_NOTICE,
      SearchFilter.HAS_CONTRACT_AWARD_NOTICE,
      SearchFilter.HAS_DESIGN_CONTEST,
      SearchFilter.HAS_AWARD_MODIFICATIONS,
      SearchFilter.HAS_CORRIGENDA,
      SearchFilter.HAS_OPENING_REPORT,
      SearchFilter.HAS_MUNICIPAL_DECISION,
    ];
  }

  export function getAddendaFilters(): SearchFilter[] {
    return [SearchFilter.HAS_ADDENDA, SearchFilter.DOES_NOT_HAVE_ADDENDA];
  }

  export function translateContainsFieldsFilters(searchFilters: SearchFilterState): string[] {
    return translateContainsFieldsFilterStrings(searchFilters.selectedFilters);
  }

  export function translateContainsFieldsFilterStrings(selectedFilters: string[]): string[] {
    let publicationTypes: string[] = [];
    for (const f of Array.from(selectedFilters)) {
      if (f === SearchFilter.HAS_PRIOR_INFORMATION_NOTICE) publicationTypes.push("priorInformationNoticeLots");
      else if (f === SearchFilter.HAS_CONTRACT_NOTICE) publicationTypes.push("contractNoticeLots");
      else if (f === SearchFilter.HAS_CONTRACT_AWARD_NOTICE) publicationTypes.push("contractAwards");
      else if (f === SearchFilter.HAS_DESIGN_CONTEST) publicationTypes.push("designContests");
      else if (f === SearchFilter.HAS_AWARD_MODIFICATIONS) publicationTypes.push("awardModifications");
      else if (f === SearchFilter.HAS_CORRIGENDA) publicationTypes.push("corrigendumChanges");
      else if (f === SearchFilter.HAS_OPENING_REPORT) publicationTypes.push("openingReportBids");
      else if (f === SearchFilter.HAS_MUNICIPAL_DECISION) publicationTypes.push("municipalDecisions");
      else if (f === SearchFilter.HAS_ADDENDA) publicationTypes.push("addenda");
      else if (f === SearchFilter.HAS_AWARDS) publicationTypes.push("awardCompanyNames");
    }
    return publicationTypes;
  }

  export function translateContainsFieldsFiltersBack(fieldNames: string[] | undefined): SearchFilter[] {
    if (fieldNames == null) return [];
    let filters: SearchFilter[] = [];
    for (const f of fieldNames) {
      if (f === "priorInformationNoticeLots") filters.push(SearchFilter.HAS_PRIOR_INFORMATION_NOTICE);
      if (f === "contractNoticeLots") filters.push(SearchFilter.HAS_CONTRACT_NOTICE);
      if (f === "contractAwards") filters.push(SearchFilter.HAS_CONTRACT_AWARD_NOTICE);
      if (f === "designContests") filters.push(SearchFilter.HAS_DESIGN_CONTEST);
      if (f === "awardModifications") filters.push(SearchFilter.HAS_AWARD_MODIFICATIONS);
      if (f === "corrigendumChanges") filters.push(SearchFilter.HAS_CORRIGENDA);
      if (f === "openingReportBids") filters.push(SearchFilter.HAS_OPENING_REPORT);
      if (f === "municipalDecisions") filters.push(SearchFilter.HAS_MUNICIPAL_DECISION);
      if (f === "addenda") filters.push(SearchFilter.HAS_ADDENDA);
      if (f === "awardCompanyNames") filters.push(SearchFilter.HAS_AWARDS);
    }
    return filters;
  }

  export function translateDoesNotContainFieldsFilters(searchFilters: SearchFilterState): string[] {
    let publicationTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.DOES_NOT_HAVE_PRIOR_INFORMATION_NOTICE)
        publicationTypes.push("priorInformationNoticeLots");
      if (f === SearchFilter.DOES_NOT_HAVE_CONTRACT_NOTICE) publicationTypes.push("contractNoticeLots");
      if (f === SearchFilter.DOES_NOT_HAVE_CONTRACT_AWARD_NOTICE) publicationTypes.push("contractAwards");
      if (f === SearchFilter.DOES_NOT_HAVE_DESIGN_CONTEST) publicationTypes.push("designContests");
      if (f === SearchFilter.DOES_NOT_HAVE_AWARD_MODIFICATIONS) publicationTypes.push("awardModifications");
      if (f === SearchFilter.DOES_NOT_HAVE_CORRIGENDA) publicationTypes.push("corrigendumChanges");
      if (f === SearchFilter.DOES_NOT_HAVE_OPENING_REPORT) publicationTypes.push("openingReportBids");
      if (f === SearchFilter.DOES_NOT_HAVE_ADDENDA) publicationTypes.push("addenda");
    }
    return publicationTypes;
  }

  export function getProcedureTypeFilters(): SearchFilter[] {
    return [
      SearchFilter.PROCEDURE_TYPE_OPEN,
      SearchFilter.PROCEDURE_TYPE_RESTRICTED,
      SearchFilter.PROCEDURE_TYPE_NEGOTIATED,
      SearchFilter.PROCEDURE_TYPE_COMPETITIVE_DIALOGUE,
      SearchFilter.PROCEDURE_TYPE_INNOVATION_PARTNERSHIP,
      SearchFilter.PROCEDURE_TYPE_OTHER,
    ];
  }

  export function translateProcedureTypeFilters(searchFilters: SearchFilterState): string[] {
    let procedureTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f.startsWith("PROCEDURE_TYPE_")) procedureTypes.push(f);
    }
    return procedureTypes;
  }

  export function getFieldGroupFilters(): SearchFilter[] {
    return [
      // SearchFilter.FIELD_GROUP_TITLE,
      // SearchFilter.FIELD_GROUP_DESCRIPTION,
      // SearchFilter.FIELD_GROUP_SPECIFICATIONS,
      SearchFilter.FIELD_GROUP_ADDENDA,
    ];
  }

  export function translateFieldGroupFilters(searchFilters: SearchFilterState): FieldGroup[] {
    let fieldGroups: FieldGroup[] = [];
    let defaultFieldGroups: FieldGroup[] = [
      FieldGroup.TITLE,
      FieldGroup.DESCRIPTION,
      FieldGroup.CONTRACTING_AUTHORITY,
      FieldGroup.FULLCONTENT,
    ];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.FIELD_GROUP_TITLE) fieldGroups.push(FieldGroup.TITLE);
      if (f === SearchFilter.FIELD_GROUP_DESCRIPTION) fieldGroups.push(FieldGroup.DESCRIPTION);
      if (f === SearchFilter.FIELD_GROUP_SPECIFICATIONS) fieldGroups.push(FieldGroup.SPECIFICATIONS);
      if (f === SearchFilter.FIELD_GROUP_AWARD_COMPANY_NAMES) fieldGroups.push(FieldGroup.AWARD_COMPANY_NAMES);
      if (f === SearchFilter.FIELD_GROUP_BID_COMPANY_NAMES) fieldGroups.push(FieldGroup.BID_COMPANY_NAMES);
      if (f === SearchFilter.FIELD_GROUP_CONTRACTING_AUTHORITY) fieldGroups.push(FieldGroup.CONTRACTING_AUTHORITY);
      if (f === SearchFilter.FIELD_GROUP_ADDENDA) {
        fieldGroups.push(FieldGroup.ADDENDA);
        defaultFieldGroups.forEach((f) => fieldGroups.push(f));
      }
    }
    if (fieldGroups.length === 0) defaultFieldGroups.forEach((f) => fieldGroups.push(f));
    return fieldGroups;
  }

  export function getPublicationRadiusFilters(): SearchFilter[] {
    return [SearchFilter.PUBLICATION_RADIUS_EUROPEAN_UNION, SearchFilter.PUBLICATION_RADIUS_NATIONAL];
  }

  export function translatePublicationRadiusFilters(searchFilters: SearchFilterState): string | undefined {
    let publicationRadiuses: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.PUBLICATION_RADIUS_EUROPEAN_UNION) publicationRadiuses.push("EUROPEAN_UNION");
      if (f === SearchFilter.PUBLICATION_RADIUS_NATIONAL) publicationRadiuses.push("NATIONAL");
    }
    if (publicationRadiuses.length === 1) return publicationRadiuses[0];
    else return undefined;
  }

  export function getContractTypeFilters(): SearchFilter[] {
    return [SearchFilter.CONTRACT_TYPE_SERVICES, SearchFilter.CONTRACT_TYPE_WORKS, SearchFilter.CONTRACT_TYPE_SUPPLIES];
  }

  export function translateContractTypeFilters(searchFilters: SearchFilterState): string[] {
    let contractTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f === SearchFilter.CONTRACT_TYPE_SERVICES) contractTypes.push("SERVICES");
      if (f === SearchFilter.CONTRACT_TYPE_WORKS) contractTypes.push("WORKS");
      if (f === SearchFilter.CONTRACT_TYPE_SUPPLIES) contractTypes.push("SUPPLIES");
    }
    return contractTypes;
  }

  export function getContractingAuthorityTypeFilters(): SearchFilter[] {
    return [
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_CGA,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_RA,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_LA,
      // SearchFilter.CONTRACTING_AUTHORITY_TYPE_DEF_CONT,//temporarily added to 'other' because there are no instances
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_UTILITIES,
      SearchFilter.CONTRACTING_AUTHORITY_TYPE_OTHER,
    ];
  }

  export function translateContractingAuthorityTypeFilters(searchFilters: SearchFilterState): string[] {
    let contractingAuthorityTypes: string[] = [];
    for (const f of Array.from(searchFilters.selectedFilters)) {
      if (f.startsWith("CONTRACTING_AUTHORITY_TYPE_")) contractingAuthorityTypes.push(f);
    }
    return contractingAuthorityTypes;
  }
}
