import React from "react";
import {useTranslation} from "react-i18next";
import {DateUtil} from "../../../../utils/date";
import {CloseIcon} from "../../../../components/icons";
import {motion} from "framer-motion";
import {FADE_IN} from "../../../../consts/FramerMotionVariants";
import {DateFilter, DateFilterType} from "../../../../hooks/slices/tenderSearchSlice";

interface DateFilterButtonProps {
    label: string;
    selectedDateFilters: DateFilter[] | undefined,
    type: DateFilterType;
    canRemove: boolean;
    removeDateFilters: () => void;
}

export const DateFilterButton: React.FC<DateFilterButtonProps> = ({label, selectedDateFilters, type, canRemove, removeDateFilters}) => {
    const {t} = useTranslation();
    let selectedFilterOfType: DateFilter | undefined = selectedDateFilters?.find(f => f.type === type);
    let start = selectedFilterOfType?.startDate;
    let end = selectedFilterOfType?.endDate;
    if (!start && !end) return <></>;
    let startString = start ? DateUtil.getDateMoment(start).format("DD-MM-YYYY") : "..."
    let endString = end ? DateUtil.getDateMoment(end).format("DD-MM-YYYY") : "..."
    let fullString = label + ": " + startString + " " + t("searchFilterTags.until") + " " + endString;
    if(!end && start && start === DateUtil.getDateString(DateUtil.today())) fullString = label + ": " + t("searchFilterTags.notYetExpired")
    return <>
        <motion.button variants={FADE_IN} style={canRemove ? {} : {borderColor: 'var(--bg-light-grey)'}}>
            <span style={canRemove ? {} : {paddingRight: 0}}>{fullString}</span>
            {canRemove && <span onClick={removeDateFilters} style={{cursor:"pointer"}} data-cy={"date-filter-close-button"}><CloseIcon/></span>}
        </motion.button>
    </>
}