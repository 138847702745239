import {ResourceKey} from "i18next";

export const SearchFilters: ResourceKey ={
	FIELD_GROUP_TITLE: "Titre",
	FIELD_GROUP_DESCRIPTION: "Description",
	FIELD_GROUP_SPECIFICATIONS: "Description Spécifications",
	FIELD_GROUP_ADDENDA: "Spécifications Addenda",
    FIELD_GROUP_AWARD_COMPANY_NAMES:"Gagnants",
    FIELD_GROUP_BID_COMPANY_NAMES:"Participants",
	COUNTRY_BE: "Belgique",
	COUNTRY_NL: "Pays-Bas",
	COUNTRY_FR: "France",
	COUNTRY_LU: "Luxembourg",
	LANGUAGE_NL: "Néerlandais",
	LANGUAGE_FR: "Français",
	LANGUAGE_DE: "Allemand",
	LANGUAGE_EN: "Anglais",
	HAS_PRIOR_INFORMATION_NOTICE: "Préinformation",
	HAS_CONTRACT_NOTICE: "Avis de marché",
	HAS_CONTRACT_AWARD_NOTICE: "Attribution",
	HAS_DESIGN_CONTEST: "Concours",
	HAS_CORRIGENDA: "Rectification",
	HAS_AWARD_MODIFICATIONS: "Modification de l'attribution",
	HAS_OPENING_REPORT: "PV d'ouverture",
	HAS_MUNICIPAL_DECISION: "Rapport du Conseil municipal",
	CONTRACT_TYPE_SERVICES: "Services",
	CONTRACT_TYPE_WORKS: "Travaux Travaux",
	CONTRACT_TYPE_SUPPLIES: "Travaux Fournitures",
	CONTRACTING_AUTHORITY_TYPE_EU_INSTITUTION: "Organisation internationale",
	CONTRACTING_AUTHORITY_TYPE_MINISTRY: "Ministère",
	CONTRACTING_AUTHORITY_TYPE_UTILITIES: "Services publics",
	CONTRACTING_AUTHORITY_TYPE_BODY_PUBLIC: "Organisme de droit public",
	CONTRACTING_AUTHORITY_TYPE_RA: "Autorité régionale",
	CONTRACTING_AUTHORITY_TYPE_LA: "Autorité locale",
	CONTRACTING_AUTHORITY_TYPE_CGA: "Agence du gouvernement central",
	CONTRACTING_AUTHORITY_TYPE_DEF_CONT: "Contractant du secteur de la défense",
	CONTRACTING_AUTHORITY_TYPE_PUB_UNDERT: "Entreprise publique",
	CONTRACTING_AUTHORITY_TYPE_REGIONAL_AGENCY: "Agence régionale",
	CONTRACTING_AUTHORITY_TYPE_NATIONAL_AGENCY: "Agence nationale",
	CONTRACTING_AUTHORITY_TYPE_OTHER: "Autre",
	PUBLICATION_RADIUS_EUROPEAN_UNION: "Oui",
	PUBLICATION_RADIUS_NATIONAL: "Non",
	HAS_ADDENDA: "Oui",
	DOES_NOT_HAVE_ADDENDA: "Non",
	PROCEDURE_TYPE_OPEN: "Ouverte",
	PROCEDURE_TYPE_RESTRICTED: "Restreinte",
	PROCEDURE_TYPE_COMPETITIVE_DIALOGUE: "Dialogue compétitive",
	PROCEDURE_TYPE_INNOVATION_PARTNERSHIP: "Partenariat d'innovation",
	PROCEDURE_TYPE_NEGOTIATED: "Négociée",
	PROCEDURE_TYPE_OTHER: "Autre",
	IS_FRAMEWORK_AGREEMENT_YES: "Oui",
	IS_FRAMEWORK_AGREEMENT_NO: "Non",
	IS_SHELTERED_WORKSHOP: "Réservé aux ateliers protégés",
	IS_SHELTERED_PROGRAM: "Programmes d'emploi protégé",
};
