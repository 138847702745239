import { FetchArgs } from "@reduxjs/toolkit/dist/query";
import { apiSlice } from "./apiSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Tender} from "../../types/tender";
import { SearchFilter } from "../../consts/SearchFilter";
import { PublicationDateToSortBy } from "../../consts/publicationDateToSortBy";
import { UserInteraction } from "./userSlice";
import { LabelTenderDto } from "./labelSlice";
import { SearchType } from "../../consts/searchType";
import { Label } from "../../types/label";
import { PURGE } from "redux-persist";
import { UserInteractionType } from "../../consts/UserInteractionType";
import { CodeBranch, CodeWithClass } from "../../types/code";
import { FieldGroup } from "../../consts/FieldGroup";
import { FilterGroup } from "../../consts/FilterGroup";
import { SortBy } from "../../consts/SortBy";
import {SearchPhase} from "../../consts/searchPhase";

/*
  REDUX STORE stuff
*/

//todo maybe add changed variable
export interface TenderSearchState {
  searchType?: SearchType;
  searchFilters: SearchFilterState;
  searchInput?: TenderSearchInput; //calculated on the basis of searchFilters + opportunities + interactions
  searchResponse?: SearchResponse; //first search
  responseLabels?: LabelTenderDto[]; //second search
  aggregations?: TenderSearchAggregation[]; //third search
  resetSearchToInitialState?: boolean;
}

const searchFilterInitialState: SearchFilterState = {
  query: "",
  sortBy: SortBy.DATE,
  publicationDateToSortBy: PublicationDateToSortBy.PUBLICATION_DATE_DESC,
  page: 0,
  pageSize: 10,
  selectedFilters: [],
};

const initialState: TenderSearchState = {
  searchInput: undefined,
  searchType: undefined,
  searchFilters: searchFilterInitialState,
  searchResponse: undefined,
  aggregations: undefined,
  responseLabels: undefined,
};

export interface QueryTypeInput {
  queryType: SearchType;
  newInitialSearchFilterState: SearchFilterState;
  userInteractions: UserInteraction[];
}

export const tenderSearchSlice = createSlice({
  initialState,
  name: "tenderSearch",
  reducers: {
    updateSearchInput(state, action: PayloadAction<TenderSearchInput>) {
      state.searchInput = action.payload;
    },
    updateSearchResponse(state, action: PayloadAction<SearchResponse>) {
      state.searchResponse = action.payload;
    },
    updateAggregations(state, action: PayloadAction<TenderSearchAggregation[]>) {
      state.aggregations = action.payload;
    },
    updateHighlights(state, action: PayloadAction<TenderSearchHit[]>) {
      if(state.searchResponse
          && state.searchResponse.tenders.length > 0
          && state.searchResponse.tenders.length === action.payload.length){
        for(const tender of state.searchResponse.tenders){
            let uuid = tender.tender.uuid;
            tender.matchedTerms = action.payload.filter(t => t.tender.uuid === uuid).map(t => t.matchedTerms).flat().filter(t => !t.includes('?'));
            tender.matchedQueries = action.payload.filter(t => t.tender.uuid === uuid).map(t => t.matchedQueries).flat();
            tender.highlightedAddenda = action.payload.filter(t => t.tender.uuid === uuid).map(t => t.highlightedAddenda).flat();
        }
      }
    },
    resetAggregations(state) {
      state.aggregations = undefined;
    },
    updateResponseLabels(state, action: PayloadAction<LabelTenderDto[]>) {
      state.responseLabels = action.payload;
    },
    addLabelToResponseLabelsIfNew(state, action: PayloadAction<LabelTenderDto>) {
      if (state.responseLabels) {
        let labelAlreadyPresent =
          state.responseLabels.filter(
            (l) => l.tenderUuid === action.payload.tenderUuid && l.label.id === action.payload.label.id
          ).length > 0;
        if (!labelAlreadyPresent) state.responseLabels = [...state.responseLabels, action.payload];
      }
    },
    removeLabelFromResponseLabels(state, action: PayloadAction<LabelTenderDto>) {
      if (state.responseLabels)
        state.responseLabels = state.responseLabels.filter(
          (l) => !(l.tenderUuid === action.payload.tenderUuid && l.label.id === action.payload.label.id)
        );
    },
    resetLabels(state) {
      state.responseLabels = undefined;
    },
    changeSearchType(state, action: PayloadAction<QueryTypeInput>) {
        state.searchInput = undefined;
        state.searchType = action.payload.queryType;
        state.searchFilters = getNewSearchFilters(
          {
            //opportunities need to be converted to uuid filters if present
            savedSearches: action.payload.newInitialSearchFilterState.savedSearches,
            searchShortlist: action.payload.newInitialSearchFilterState.searchShortlist,
            userInteractions: action.payload.userInteractions,
          },
            action.payload.newInitialSearchFilterState,
            action.payload.userInteractions,
            action.payload.queryType === SearchType.OPPORTUNITIES
        );
        state.searchResponse = undefined;
        state.aggregations = undefined;
        //opportunities are not reset here because they are still valid
        //interactions are not reset here because they are still valid
        state.responseLabels = undefined;
    },
    resetSearch(state) {
      state.searchInput = undefined;
      state.searchType = undefined;
      state.searchFilters = searchFilterInitialState;
      state.searchResponse = undefined;
      state.aggregations = undefined;
      // state.cues = undefined;
      //interactions and cues are not reset here because they are still valid (and also there's a strange loading error if they are)
      state.responseLabels = undefined;
      state.resetSearchToInitialState = false;
    },
    resetSearchToInitialState(state, action: PayloadAction<{reset: boolean}>) {
      state.resetSearchToInitialState = action.payload.reset;
    },
    updateSearchFilters(state, action: PayloadAction<SearchFilterUpdateProps>) {
      if (action.payload.page === undefined) state.aggregations = undefined; //if page is changed the aggregations remain the same (only the Paginate element changes the page)
      state.searchFilters = getNewSearchFilters(action.payload, state.searchFilters, action.payload.userInteractions, state.searchType === SearchType.OPPORTUNITIES);
    },
    resetDateFilters(state, action: PayloadAction<{type: DateFilterType}>) {
      state.searchFilters.selectedDateFilters = state.searchFilters.selectedDateFilters?.filter(f => f.type !== action.payload.type);
    },
    updateTenderUuidFilters(state, action: PayloadAction<string[]>) {
      state.searchFilters.tenderUuidFilters = action.payload;
    },
    resetVatNumberFilters(state) {
      state.searchFilters.contractingAuthorityVatNumbers = undefined;
      state.searchFilters.participantVatNumbers = undefined;
    },
    resetSortByTenderUuidFilters(state) {
      state.searchFilters.similarTender = undefined;
      state.searchFilters.tenderUuidsToIgnore = undefined; //the similar uuid needs to be ignored, so this will always be set in tandem
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      state.searchInput = initialState.searchInput;
      state.searchType = initialState.searchType;
      state.searchFilters = initialState.searchFilters;
      state.searchResponse = initialState.searchResponse;
      state.aggregations = initialState.aggregations;
      state.responseLabels = initialState.responseLabels;
    });
  },
});

function getNewSearchFilters(
  newFilters: SearchFilterUpdateProps,
  currentFilters: SearchFilterState,
  useInteractions: UserInteraction[],
  opportunitySearch: boolean
): SearchFilterState {
  return {
    accreditations: newFilters.accreditations !== undefined ? newFilters.accreditations : currentFilters.accreditations,
    query: newFilters.query !== undefined ? newFilters.query : currentFilters.query,
    sortBy: newFilters.sortBy !== undefined ? newFilters.sortBy : currentFilters.sortBy,
    publicationDateToSortBy: newFilters.dateToSortBy !== undefined ? newFilters.dateToSortBy : currentFilters.publicationDateToSortBy,
    page: newFilters.page !== undefined ? newFilters.page : 0, //when filters change the page needs to be reset
    pageSize: newFilters.pageSize !== undefined ? newFilters.pageSize : currentFilters.pageSize, //when filters change the page needs to be reset
    selectedFilters: newFilters.selectedFilters !== undefined ? newFilters.selectedFilters : currentFilters.selectedFilters,
    // hiddenFilters: newFilters.hiddenFilters !== undefined ? newFilters.hiddenFilters : localSearchFilters.hiddenFilters,
    selectedDateFilters: newFilters.dateFilters !== undefined ? newFilters.dateFilters : currentFilters.selectedDateFilters,
    subjectCodes: newFilters.subjectCodes !== undefined ? newFilters.subjectCodes : currentFilters.subjectCodes,
    regionCodes: newFilters.regionCodes !== undefined ? newFilters.regionCodes : currentFilters.regionCodes,
    labelFilters: newFilters.labelFilters !== undefined ? newFilters.labelFilters : currentFilters.labelFilters,
    tenderUuidFilters:
      newFilters.searchShortlist !== undefined
        ? getTenderUuidFilters(newFilters.searchShortlist, useInteractions)
        : currentFilters.tenderUuidFilters,
    // companyFilters: newFilters.companyFilters !== undefined ? newFilters.companyFilters: currentFilters.companyFilters,
    tenderUuidsToIgnore: getTenderUuidsToIgnore(newFilters, useInteractions, opportunitySearch) ?? currentFilters.tenderUuidsToIgnore,
    savedSearches: newFilters.savedSearches !== undefined ? newFilters.savedSearches : currentFilters.savedSearches,
    searchShortlist: newFilters.searchShortlist !== undefined ? newFilters.searchShortlist : currentFilters.searchShortlist,
    contractingAuthorityVatNumbers: newFilters.contractingAuthorityVatNumbers !== undefined ? newFilters.contractingAuthorityVatNumbers : currentFilters.contractingAuthorityVatNumbers,
    participantVatNumbers: newFilters.participantVatNumbers !== undefined ? newFilters.participantVatNumbers : currentFilters.participantVatNumbers,
    similarTender: newFilters.similarTender !== undefined ? newFilters.similarTender : currentFilters.similarTender,
  };
}

function getTenderUuidsToIgnore(newFilters: SearchFilterUpdateProps, userInteractions: UserInteraction[], opportunitySearch: boolean): string[] | undefined
{
  if(opportunitySearch && newFilters.savedSearches != null){
    return getTenderUuidsToIgnoreFromDeletedOpportunities(newFilters.savedSearches.length > 0, userInteractions)
  } else if(newFilters.similarTender != null){
    return [newFilters.similarTender.uuid]
  } else return undefined;
}
//
// //cases:
// // 1 - Non-date-related update
// // 2 - Regular date range update
// // 3 - Custom date range update
//
// //todo find a way to pass 'earliest start date'? or just trust the filters
// function getPublicationStartDate(newFilters: SearchFilterUpdateProps, currentFilters: SearchFilterState) {
//   let publicationDateFilter: SearchFilter | undefined = SearchFilterUtil.findPublicationDateFilter(
//     newFilters.selectedFilters
//   );
//   let customUpdateFilterIsPresent = publicationDateFilter === SearchFilter.PUBLICATION_DATE_CUSTOM;
//   let customUpdate = customUpdateFilterIsPresent && newFilters.startPublicationDate;
//   let regularUpdate = !customUpdateFilterIsPresent && publicationDateFilter;
//   if (customUpdate) return newFilters.startPublicationDate;
//   else if (regularUpdate && publicationDateFilter)
//     return SearchFilterUtil.findPublicationStartDate([publicationDateFilter], undefined);
//   else return currentFilters.startPublicationDate; //case: unrelated update
// }
//
// function getPublicationEndDate(newFilters: SearchFilterUpdateProps, currentFilters: SearchFilterState) {
//   let publicationDateFilter: SearchFilter | undefined = SearchFilterUtil.findPublicationDateFilter(
//     newFilters.selectedFilters
//   );
//   let customUpdateFilterIsPresent = publicationDateFilter === SearchFilter.PUBLICATION_DATE_CUSTOM;
//   let customUpdate = customUpdateFilterIsPresent && newFilters.endPublicationDate;
//   let regularUpdate = !customUpdateFilterIsPresent && publicationDateFilter;
//   if (customUpdate) return newFilters.endPublicationDate;
//   else if (regularUpdate && publicationDateFilter)
//     return SearchFilterUtil.findPublicationEndDate([publicationDateFilter]);
//   else return currentFilters.endPublicationDate; //case: unrelated update
// }
//
// function getAwardOrOpeningReportPublicationStartDate(
//   newFilters: SearchFilterUpdateProps,
//   currentFilters: SearchFilterState
// ) {
//   let publicationDateFilter: SearchFilter | undefined = SearchFilterUtil.findAwardOrOpeningReportPublicationDateFilter(
//     newFilters.selectedFilters
//   );
//   let customUpdateFilterIsPresent =
//     publicationDateFilter === SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM;
//   let customUpdate: boolean = customUpdateFilterIsPresent && newFilters.awardOrOpeningReportPublicationStartDate != null;
//   let regularUpdate: boolean = !customUpdateFilterIsPresent && publicationDateFilter != null;
//   if (customUpdate) return newFilters.awardOrOpeningReportPublicationStartDate;
//   else if (regularUpdate && publicationDateFilter)
//     return SearchFilterUtil.findPublicationStartDate([publicationDateFilter], undefined);
//   else return currentFilters.awardOrOpeningReportPublicationStartDate; //case: unrelated update
// }
//
// function getAwardOrOpeningReportPublicationEndDate(
//   newFilters: SearchFilterUpdateProps,
//   currentFilters: SearchFilterState
// ) {
//   let publicationDateFilter: SearchFilter | undefined = SearchFilterUtil.findAwardOrOpeningReportPublicationDateFilter(
//     newFilters.selectedFilters
//   );
//   let customUpdateFilterIsPresent =
//     publicationDateFilter === SearchFilter.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM;
//   let customUpdate: boolean = customUpdateFilterIsPresent && newFilters.awardOrOpeningReportPublicationEndDate != null;
//   let regularUpdate: boolean = !customUpdateFilterIsPresent && publicationDateFilter != null;
//   if (customUpdate) return newFilters.awardOrOpeningReportPublicationEndDate;
//   else if (regularUpdate && publicationDateFilter)
//     return SearchFilterUtil.findPublicationEndDate([publicationDateFilter]);
//   else return currentFilters.awardOrOpeningReportPublicationEndDate; //case: unrelated update
// }
//
// function getDeadlineStartDate(newFilters: SearchFilterUpdateProps, currentFilters: SearchFilterState) {
//   let deadlineDateFilter: SearchFilter | undefined = SearchFilterUtil.findDeadlineDateFilter(
//     newFilters.selectedFilters
//   );
//   let customUpdateFilterIsPresent = deadlineDateFilter === SearchFilter.DEADLINE_CUSTOM;
//   let customUpdate: boolean = customUpdateFilterIsPresent && newFilters.startDeadlineDate != null;
//   let regularUpdate: boolean = !customUpdateFilterIsPresent && deadlineDateFilter != null;
//   if (customUpdate) return newFilters.startDeadlineDate;
//   else if (regularUpdate && deadlineDateFilter) return SearchFilterUtil.findDeadlineStartDate([deadlineDateFilter]);
//   else return currentFilters.startDeadlineDate; //case: unrelated update
// }
//
// function getDeadlineEndDate(newFilters: SearchFilterUpdateProps, currentFilters: SearchFilterState) {
//   let deadlineDateFilter: SearchFilter | undefined = SearchFilterUtil.findDeadlineDateFilter(
//     newFilters.selectedFilters
//   );
//   let customUpdateFilterIsPresent = deadlineDateFilter === SearchFilter.DEADLINE_CUSTOM;
//   let customUpdate: boolean = customUpdateFilterIsPresent && newFilters.endDeadlineDate != null;
//   let regularUpdate: boolean = !customUpdateFilterIsPresent && deadlineDateFilter != null;
//   if (customUpdate) return newFilters.endDeadlineDate;
//   else if (regularUpdate && deadlineDateFilter) return SearchFilterUtil.findDeadlineEndDate([deadlineDateFilter]);
//   else return currentFilters.endDeadlineDate; //case: unrelated update
// }
//
// function getEstimatedRenewalStartDate(newFilters: SearchFilterUpdateProps, currentFilters: SearchFilterState) {
//     let estimatedRenewalDateFilter: SearchFilter | undefined = SearchFilterUtil.findEstimatedRenewalDateFilter(
//         newFilters.selectedFilters
//     );
//     let customUpdateFilterIsPresent = estimatedRenewalDateFilter === SearchFilter.ESTIMATED_RENEWAL_DATE_CUSTOM;
//     let customUpdate: boolean = customUpdateFilterIsPresent && newFilters.estimatedRenewalStartDate != null;
//     let regularUpdate: boolean = !customUpdateFilterIsPresent && estimatedRenewalDateFilter != null;
//     if (customUpdate) return newFilters.estimatedRenewalStartDate;
//     else if (regularUpdate && estimatedRenewalDateFilter) return SearchFilterUtil.getEstimatedRenewalStartDate([estimatedRenewalDateFilter]);
//     else {
//       return currentFilters.estimatedRenewalStartDate;
//     } //case: unrelated update
// }
//
// function getEstimatedRenewalEndDate(newFilters: SearchFilterUpdateProps, currentFilters: SearchFilterState) {
//     let estimatedRenewalDateFilter: SearchFilter | undefined = SearchFilterUtil.findEstimatedRenewalDateFilter(
//         newFilters.selectedFilters
//     );
//     let customUpdateFilterIsPresent: boolean = estimatedRenewalDateFilter === SearchFilter.ESTIMATED_RENEWAL_DATE_CUSTOM;
//     let customUpdate: boolean = customUpdateFilterIsPresent && newFilters.estimatedRenewalEndDate != null;
//     let regularUpdate: boolean = !customUpdateFilterIsPresent && estimatedRenewalDateFilter != null;
//     if (customUpdate) return newFilters.estimatedRenewalEndDate;
//     else if (regularUpdate && estimatedRenewalDateFilter) return SearchFilterUtil.getEstimatedRenewalEndDate([estimatedRenewalDateFilter]);
//     else return currentFilters.estimatedRenewalEndDate; //case: unrelated update
// }

function getTenderUuidFilters(
  searchLeads: boolean | undefined,
  userInteractions: UserInteraction[] | undefined
) {
  let tenderUuids: string[] = [];
  if (searchLeads && userInteractions) {
    userInteractions
      .filter((i) => i.interactionType === UserInteractionType.MAKE_LEAD)
      .map((i) => i.uuid)
      .forEach((uuid) => tenderUuids.push(uuid));
  }
  if (tenderUuids.length === 0 && searchLeads) {
    tenderUuids = ["dummmy_uuid"]; //otherwise all publications are returned
  }
  return tenderUuids;
}

function getTenderUuidsToIgnoreFromDeletedOpportunities(
  searchOpportunities: boolean | undefined,
  userInteractions: UserInteraction[] | undefined
) {
  let tenderUuids: string[] = []; //this only ignores in the context of opportunities
  if (searchOpportunities && userInteractions) {
    userInteractions
      .filter((i) => i.interactionType === UserInteractionType.DELETE_OPPORTUNITY)
      .map((i) => i.uuid)
      .forEach((uuid) => tenderUuids.push(uuid));
  }
  return tenderUuids;
}

export const {
  changeSearchType,
  resetSearch,
  updateSearchFilters,resetSearchToInitialState,
  resetLabels, resetVatNumberFilters,
  resetSortByTenderUuidFilters,
  resetAggregations,
  resetDateFilters,
  updateTenderUuidFilters,
  addLabelToResponseLabelsIfNew,
  removeLabelFromResponseLabels,
  updateResponseLabels,
  updateSearchInput,
  updateSearchResponse,
  updateAggregations,
  updateHighlights
} = tenderSearchSlice.actions;

/*
  API connection stuff
*/

export interface TenderSearchInput {
  metadata: TenderSearchMetadata;
  mainInput: TenderSearch;
  filterInputs: TenderSearch[];
}

export interface TenderSearch {
  uuid?: string;
  userUuid?: string;
  curatedProfileId?: number;
  isActive?: boolean;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  accreditations?: CodeWithClass[];
  containsFields?: string[];
  contractTypes?: string[];
  contractingAuthorityTypes?: string[];
  countries?: string[];
  deadlineStartDate?: string; //"2022-01-01T12:00"
  deadlineEndDate?: string; //"2022-01-01T12:00"
  doesNotContainFields?: string[];
  fieldGroups?: FieldGroup[];
  filterGroups?: FilterGroup[];
  languageIsos?: string[];
  procedureTypes?: string[];
  publicationStartDate?: string; //"2022-01-01"
  publicationEndDate?: string; //"2022-01-01"
  awardOrOpeningReportPublicationStartDate?: string; //"2022-01-01"
  awardOrOpeningReportPublicationEndDate?: string; //"2022-01-01"
  estimatedRenewalStartDate?: string; //"2022-01-01"
  estimatedRenewalEndDate?: string; //"2022-01-01"
  publicationRadius?: string;
  query?: string;
  regionCodes?: CodeBranch[];
  subjectCodes?: CodeBranch[];
  isFrameworkAgreement?: boolean;
  isShelteredWorkshop?: boolean;
  isShelteredProgram?: boolean;
  contractingAuthorityVatNumbers?: string[]; //eg BE0000.000.000
  participantVatNumbers?: string[]; //eg BE0000.000.000
}

export interface TenderSearchMetadata {
  searchType: SearchType;
  searchPhase: SearchPhase;
  page?: number;
  pageSize?: number;
  addAggregations?: boolean;
  addMainQueryHighlights?: boolean;
  addFilterQueryHighlights?: boolean;
  highlightSize?: number;
  maxHighlightedFilesPerTender?: number;
  maxHighlights?: number;
  sortBy?: string; //eg PUBLICATION_DATE_ASC
  fieldsToFetch?: string[];
  labelFilters?: Label[];
  tenderUuidFilters?: string[];
  tenderUuidsToIgnore?: string[];
  sortBySimilarityTo?: string[];
}

export interface SearchFilterState {
  query: string;
  sortBy: SortBy;
  publicationDateToSortBy: PublicationDateToSortBy;
  page: number;
  pageSize: number;
  selectedFilters: SearchFilter[];
  selectedDateFilters?: DateFilter[];
  subjectCodes?: CodeBranch[];
  regionCodes?: CodeBranch[];
  accreditations?: CodeWithClass[];
  tenderUuidFilters?: string[]; //these are calculated based on opportunities and interactions
  tenderUuidsToIgnore?: string[]; //these are calculated based on opportunities and interactions
  labelFilters?: Label[];
  savedSearches?: string[];
  searchShortlist?: boolean;
  contractingAuthorityVatNumbers?: string[]; //eg BE0000.000.000
  participantVatNumbers?: string[]; //eg BE0000.000.000
  similarTender?: SimilarTenderFilter;
}

export interface DateFilter{
  type: DateFilterType;
  name: DateFilterName;
  startDate?: string;
  endDate?: string;
}

export enum DateFilterType{
    PUBLICATION_DATE,
    AWARD_OR_OPENING_REPORT_PUBLICATION_DATE,
    DEADLINE,
    ESTIMATED_RENEWAL_DATE
}


export enum DateFilterName{

  PUBLICATION_DATE_EVERYTHING="PUBLICATION_DATE_EVERYTHING",
  PUBLICATION_DATE_YESTERDAY="PUBLICATION_DATE_YESTERDAY",
  PUBLICATION_DATE_LAST_WEEK="PUBLICATION_DATE_LAST_WEEK",
  PUBLICATION_DATE_LAST_MONTH="PUBLICATION_DATE_LAST_MONTH",
  PUBLICATION_DATE_LAST_TWO_MONTHS="PUBLICATION_DATE_LAST_TWO_MONTHS",
  PUBLICATION_DATE_LAST_YEAR="PUBLICATION_DATE_LAST_YEAR",
  PUBLICATION_DATE_CUSTOM="PUBLICATION_DATE_CUSTOM",
  PUBLICATION_DATE_PREVIOUS_WEEK_ONLY="PUBLICATION_DATE_PREVIOUS_WEEK_ONLY",

  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_EVERYTHING",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_YESTERDAY",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_WEEK",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_MONTH",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_LAST_YEAR",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_CUSTOM",
  AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_PREVIOUS_WEEK_ONLY="AWARD_OR_OPENING_REPORT_PUBLICATION_DATE_PREVIOUS_WEEK_ONLY",

  DEADLINE_EVERYTHING="DEADLINE_EVERYTHING",
  DEADLINE_NOT_YET_EXPIRED="DEADLINE_NOT_YET_EXPIRED",
  DEADLINE_IN_7_DAYS="DEADLINE_IN_7_DAYS",
  DEADLINE_IN_14_DAYS="DEADLINE_IN_14_DAYS",
  DEADLINE_IN_1_MONTH="DEADLINE_IN_1_MONTH",
  DEADLINE_IN_2_MONTHS="DEADLINE_IN_2_MONTHS",
  DEADLINE_CUSTOM="DEADLINE_CUSTOM",

  ESTIMATED_RENEWAL_DATE_ALL="ESTIMATED_RENEWAL_DATE_ALL",
  ESTIMATED_RENEWAL_DATE_NEXT_MONTH="ESTIMATED_RENEWAL_DATE_NEXT_MONTH",
  ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS="ESTIMATED_RENEWAL_DATE_NEXT_THREE_MONTHS",
  ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS="ESTIMATED_RENEWAL_DATE_NEXT_SIX_MONTHS",
  ESTIMATED_RENEWAL_DATE_NEXT_YEAR="ESTIMATED_RENEWAL_DATE_NEXT_YEAR",
  ESTIMATED_RENEWAL_DATE_CUSTOM="ESTIMATED_RENEWAL_DATE_CUSTOM",
}

export interface SimilarTenderFilter {
  uuid: string;
  tag: string;
}

export interface SearchFilterUpdateProps {
  query?: string;
  sortBy?: SortBy;
  dateToSortBy?: PublicationDateToSortBy;
  page?: number;
  pageSize?: number;
  selectedFilters?: SearchFilter[];
  hiddenFilters?: SearchFilter[];
  dateFilters?: DateFilter[];
  subjectCodes?: CodeBranch[];
  regionCodes?: CodeBranch[];
  accreditations?: CodeWithClass[];
  labelFilters?: Label[];
  savedSearches?: string[];
  searchShortlist?: boolean;
  searchFrameworks?: boolean;
  contractingAuthorityVatNumbers?: string[]; //eg BE0000.000.000
  participantVatNumbers?: string[]; //eg BE0000.000.000
  similarTender?: SimilarTenderFilter;
  userInteractions: UserInteraction[];
}

export interface SearchResponse {
  tenders: TenderSearchHit[];
  page: number;
  pageSize: number;
  totalHits: number;
  responseTimeMs: number;
  sortBy: PublicationDateToSortBy;
  aggregations: TenderSearchAggregation[];
}

export interface TenderSearchHit {
  tender: Tender;
  score: number;
  highlights: TenderSearchHighlight[];
  highlightedAddenda: TenderSearchHighlightedAddendum[];
  matchedQueries: string[];
  matchedTerms: string[];
}

export interface TenderSearchHighlight {
  field: string;
  highlight: string;
}

export interface TenderSearchHighlightedAddendum {
  score: number;
  fileName: string;
  downloadUrl: string;
  highlights: string[];
}

export interface TenderSearchAggregation {
  searchFilter?: SearchFilter;
  cue?: string;
  label?: string;
  count: number;
}

/**
 * getTenderSearch and build.query constrains 'useGetTenderSearchQuery'
 * build.mutation returns [] whereas build.query returns {}
 */

const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTenderSearch: build.mutation<SearchResponse, TenderSearchInput>({
      query: (body: TenderSearchInput) => {
        let args: FetchArgs = {
          url: `/shaggy/tenders`,
          method: "POST",
          body: body,
          // responseHandler: "json",
        };
        return args;
      },
    }),
  }),
});

export const { endpoints, useGetTenderSearchMutation } = extendedApiSlice;
